'use client';

import { SignupCompletedEventProps, TrackMixpanelEvent } from '@xyla/analytics';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { HOW_DID_YOU_HEAR, SUBSCRIPTION_OCCUPATIONS } from '../constants';
import { SubscriptionUserDataPayload, UserInfo } from '../users/types';
import { SubscriptionFieldValues } from './fieldValues';
import { FieldMetadata } from './types';
import { SendMixpanelSignupEventPropsWithReferrer } from './useOnboardingBase';
import {
  constructRulesFromFieldMetadata,
  listToOptions,
  validateNotEmpty,
} from './utils';
import {
  RegistrationTypeEnum,
  SpecialtyEnum,
} from '../OEUserProfileAPI/_generated';

interface UseOnboardingSubscriptionProps {
  userInfo?: UserInfo;
  trackMixpanelEvent: TrackMixpanelEvent;
}

export function useOnboardingSubscription({
  userInfo,
  trackMixpanelEvent,
}: UseOnboardingSubscriptionProps) {
  // Set default values based on the user metadata
  const defaultOccupationString = useMemo(
    () => userInfo?.occupation ?? '',
    [userInfo]
  );
  const defaultOccupationDropdownValue = useMemo(
    () =>
      SUBSCRIPTION_OCCUPATIONS.includes(defaultOccupationString)
        ? defaultOccupationString
        : defaultOccupationString
        ? 'Other'
        : '',
    [defaultOccupationString]
  );
  const defaultOtherOccupationValue = useMemo(
    () =>
      defaultOccupationDropdownValue === 'Other' ? defaultOccupationString : '',
    [defaultOccupationDropdownValue, defaultOccupationString]
  );

  const defaultSpecialtyValue = useMemo(
    () => userInfo?.specialty ?? '',
    [userInfo]
  );

  const defaultReferrerDropdownValue = useMemo(
    () => HOW_DID_YOU_HEAR.find((value) => value === userInfo?.referrer) ?? '',
    [userInfo]
  );

  const defaultValues = useMemo(
    () => ({
      name: userInfo?.name ?? '',
      occupation: defaultOccupationDropdownValue,
      otherOccupation: defaultOtherOccupationValue,
      specialty: defaultSpecialtyValue,
      referrer: defaultReferrerDropdownValue,
    }),
    [
      userInfo,
      defaultOccupationDropdownValue,
      defaultOtherOccupationValue,
      defaultSpecialtyValue,
      defaultReferrerDropdownValue,
    ]
  );

  const form = useForm<SubscriptionFieldValues>({
    defaultValues,
  });

  // If the source data ever changes from outside of here, reset the form.
  // This is needed because in the app, we don't start off with the data from a "server"
  // the way we do on the web.
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const constructUserDataPayload = useCallback(
    (data: SubscriptionFieldValues) => {
      // Determine additional fields based on occupation
      let additionalFields = {};
      switch (data.occupation) {
        case 'Physician':
          additionalFields = {
            specialty: data.specialty as SpecialtyEnum,
          };
          break;
      }

      const body: SubscriptionUserDataPayload = {
        name: data.name.trim(),
        occupation:
          data.occupation === 'Other'
            ? data.otherOccupation.trim()
            : data.occupation,
        registrationType: RegistrationTypeEnum.Subscription,
        referrer: data.referrer,
        hasConsentedToPolicies: data.hasConsentedToPolicies,
        ...additionalFields,
      };

      return body;
    },
    []
  );

  const sendMixpanelSignupEventWithReferringPage = useCallback(
    async ({
      body,
      referringPage,
      accessLevelToGive,
      hasProcessedUser,
    }: SendMixpanelSignupEventPropsWithReferrer<SubscriptionUserDataPayload>) => {
      await trackMixpanelEvent?.('signup_completed_trusted', {
        specialty: body.specialty,

        // explicitly overwrite props that are sent on every event
        auth0Occupation: body.occupation,
        auth0AccessLevel: accessLevelToGive ?? undefined,
        auth0RegistrationType: body.registrationType,

        // misc/accounting
        referring_page: referringPage,
        skippingMetadataBecauseKnown: false,
        hasProcessedUser,
        referrer: body.referrer,
        prioritizeEventAuth0Data: true, // Need this here because we have new knowledge that is not baking into the session yet
        hasConsentedToPolicies: body.hasConsentedToPolicies,
      } satisfies SignupCompletedEventProps);
    },
    [trackMixpanelEvent]
  );

  const hasProcessedUser = useMemo(
    () => userInfo?.hasProcessedUser ?? false,
    [userInfo]
  );

  const allFieldMetadata: Record<
    keyof SubscriptionFieldValues,
    FieldMetadata<SubscriptionFieldValues>
  > = useMemo(
    () => ({
      name: {
        label: 'Name',
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please enter your name',
          validate: validateNotEmpty,
        }),
      },
      occupation: {
        label: 'Occupation',
        options: listToOptions(SUBSCRIPTION_OCCUPATIONS),
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please select your occupation',
          onChange: (e) => {
            if (e.target.value === 'Other') {
              form.setValue('otherOccupation', '');
              form.clearErrors('otherOccupation');
            }
            if (e.target.value === 'Physician') {
              form.clearErrors('specialty');
            }
          },
        }),
      },
      otherOccupation: {
        label: 'Specify occupation',
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please enter your occupation',
          validate: validateNotEmpty,
        }),
      },
      specialty: {
        label: 'Specialty',
        options: listToOptions(Object.values(SpecialtyEnum)),
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please select your specialty',
        }),
      },
      referrer: {
        label: 'How did you hear about us?',
        options: listToOptions(HOW_DID_YOU_HEAR),
        rules: constructRulesFromFieldMetadata({
          required: false,
          errorMessage: 'Please select how you heard about us',
        }),
      },
      hasConsentedToPolicies: {
        label: '', // Provided in onboarding-patients.tsx template
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please read and agree to the policies',
        }),
      },
    }),
    [form]
  );

  return {
    // form
    form,

    // functions
    sendMixpanelSignupEventWithReferringPage,
    constructUserDataPayload,

    // helpers
    hasProcessedUser,

    // default values for dropdowns
    defaultOccupationDropdownValue,
    defaultSpecialtyValue,
    defaultReferrerDropdownValue,

    // field metadata
    allFieldMetadata,
  };
}
