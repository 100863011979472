import { Box, Typography } from '@mui/material';
import { Callout, CalloutCitedDocument } from './Callout';
import { NEJMIcon } from './icons/NEJMIcon';
import { NEJM_LICENSE } from '@xyla/openevidence-shared-functionality';

interface StudyCalloutProps {
  section_title: string;
  children: React.ReactNode;
  // Should only have one document
  documents?: CalloutCitedDocument[];
}

export const StudyCallout = ({
  section_title,
  children,
  documents,
}: StudyCalloutProps) => {
  const nejmLogo = (
    <Box sx={{ height: '24px' }}>
      <NEJMIcon />
    </Box>
  );
  const licenseAttribution = (
    // NEJM Attribution. Copied from PublicationCard
    <Typography
      sx={{
        color: 'grey.600',
        fontSize: '0.75rem',
      }}
    >
      {NEJM_LICENSE}
    </Typography>
  );

  return (
    <Callout
      title={section_title}
      headerSx={{
        backgroundColor: '#F9E5D9',
        color: '#D84A24',
      }}
      documents={documents}
      trailingHeaderElement={nejmLogo}
      belowFooterElement={licenseAttribution}
    >
      {children}
    </Callout>
  );
};
