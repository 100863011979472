/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OEUserProfileVerifyNpi,
} from '../models/index';
import {
    OEUserProfileVerifyNpiFromJSON,
    OEUserProfileVerifyNpiToJSON,
} from '../models/index';

export interface VerifyNpiRetrieveRequest {
    name: string;
    npi: string;
}

/**
 * 
 */
export class VerifyNpiApi extends runtime.BaseAPI {

    /**
     * Verify that the NPI exists and that the name matches. Stateless, any data that needs to be saved (e.g. the registry data) should also go in the normla create endpoint  If (when) we build in further NPI verification it should go here
     */
    async verifyNpiRetrieveRaw(requestParameters: VerifyNpiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfileVerifyNpi>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling verifyNpiRetrieve().'
            );
        }

        if (requestParameters['npi'] == null) {
            throw new runtime.RequiredError(
                'npi',
                'Required parameter "npi" was null or undefined when calling verifyNpiRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['npi'] != null) {
            queryParameters['npi'] = requestParameters['npi'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/verify-npi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileVerifyNpiFromJSON(jsonValue));
    }

    /**
     * Verify that the NPI exists and that the name matches. Stateless, any data that needs to be saved (e.g. the registry data) should also go in the normla create endpoint  If (when) we build in further NPI verification it should go here
     */
    async verifyNpiRetrieve(requestParameters: VerifyNpiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfileVerifyNpi> {
        const response = await this.verifyNpiRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
