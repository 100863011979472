/**
 * This file contains functions and constants related to access codes for the OpenEvidence application.
 *
 * - `ACTIVE_OE_ACCESS_CODES`: A dict mapping role names to a list of currently active access codes.
 * - `generateAccessCodes(count: number, length: number): string[]`: A function to generate a specified number of unique access codes of a given length.
 *
 * To generate new codes, run this script as follows:
 * ```
 * cd web/packages/openevidence-shared-functionality
 * npm i
 * npx tsx src/userRegistration/accessCodes.ts
 * ```
 * Then replace ACTIVE_OE_ACCESS_CODES.
 */

import {
  NONNPI_HCP_ACCESS_ROLE_NAME,
  NPI_ACCESS_ROLE_NAME,
  OEAccessRoleName,
  PATIENT_ACCESS_ROLE_NAME,
  PRIVATE_ACCESS_ROLE_NAME,
  STUDENT_ACCESS_ROLE_NAME,
  VIP_ACCESS_ROLE_NAME,
} from '../types';

// keep in sync with src/piapiac/xyla/django/openevidence_app/access_codes.py::ACTIVE_OE_ACCESS_CODES
export const ACTIVE_OE_ACCESS_CODES: Record<OEAccessRoleName, string[]> = {
  // These are randomly generated codes, given 1:1 to the email domains in the spreadsheet
  // https://docs.google.com/spreadsheets/d/1OUtwzFNP0YAvoJAdfRN73zRoEy2Dn-HjSTkX9LI0uQc/edit?gid=0#gid=0
  [VIP_ACCESS_ROLE_NAME]: [
    'XA-K9R65PE2',
    'XA-GXA7OM2E',
    'XA-D1G5HXAK',
    'XA-OYIM8I5Z',
    'XA-CFK7KR69',
    'XA-7Q7TRBZ8',
    'XA-JTO5R88K',
    'XA-68MKDXVL',
    'XA-A3MIHFOM',
    'XA-QVD46Q9D',
    'XA-PPVHJX2D',
    'XA-YHM2M80P',
    'XA-H1GRCW6X',
    'XA-BLYGTYTG',
    'XA-OUGRF7KS',
    'XA-9S4ARPYT',
    'XA-Z32W98FL',
    'XA-3ZGF9HC0',
    'XA-RU51R148',
    'XA-A6XHC0N7',
    'XA-M0DB9NQB',
    'XA-OO2ZJ5CN',
    'XA-D2DO93V4',
    'XA-QUZLDJVQ',
    'XA-UXSOQIT4',
    'XA-XR4P3FJC',
    'XA-4Z2650QN',
    'XA-9H5T38Y9',
    'XA-BMCBRFTR',
    'XA-3AJ6B4LV',
    'XA-9FJJGUKT',
    'XA-5P08J6ON',
    'XA-MVMBNBKV',
    'XA-IMYFJL5Z',
    'XA-L9VM8VE6',
    'XA-M4DYTEPT',
    'XA-V7ZQNX3R',
    'XA-QP4W3L89',
    'XA-G4P5R06T',
    'XA-18O0SGS9',
    'XA-7FE7QQIB',
    'XA-OWKB4ST1',
    'XA-BU8L3LQ3',
    'XA-WYRRUTQK',
    'XA-25K80MQP',
    'XA-P82C6C3Q',
    'XA-SIEXXLDU',
    'XA-7FJ5UDHI',
    'XA-WEHOKWXC',
    'XA-S66PADU5',
    'XA-9P4IJL27',
    'XA-JIGJ5WYR',
    'XA-3LSVFUOH',
    'XA-59ZIVI03',
    'XA-TW4T5FYL',
    'XA-F61NHYTP',
    'XA-7E87IYC5',
    'XA-0FW61ZTF',
    'XA-RGXAD4BP',
    'XA-WBT8MBR0',
    'XA-IIRYSLVK',
    'XA-YLDYPM6G',
    'XA-99Q1I17O',
    'XA-XOBAAAHW',
    'XA-PCN2FLMS',
    'XA-5XVAPMBC',
    'XA-Y8916XCV',
    'XA-XZEHX2MS',
    'XA-5JZC2FZ6',
    'XA-53WSIPV3',
    'XA-HI9QPPGC',
    'XA-WJ07P5S9',
    'XA-W8MQ414W',
    'XA-A8B6CKDU',
  ],
  [NPI_ACCESS_ROLE_NAME]: [],
  [NONNPI_HCP_ACCESS_ROLE_NAME]: [],
  [STUDENT_ACCESS_ROLE_NAME]: [],
  [PATIENT_ACCESS_ROLE_NAME]: [],
  [PRIVATE_ACCESS_ROLE_NAME]: [],
};

export function getOEAccessRoleNameFromAccessCode(
  code: string
): OEAccessRoleName | null {
  if (!code) {
    return null;
  }
  return (
    (Object.keys(ACTIVE_OE_ACCESS_CODES).find((role) =>
      ACTIVE_OE_ACCESS_CODES[role as OEAccessRoleName].includes(code)
    ) as OEAccessRoleName) ?? null
  );
}

function generateAccessCodes(count: number, length: number): string[] {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const codes = new Set<string>();
  while (codes.size < count) {
    let code = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
    code = `XA-${code}`;
    if (
      !Object.values(ACTIVE_OE_ACCESS_CODES).some((codes) =>
        codes.includes(code)
      )
    ) {
      codes.add(code);
    }
  }

  return Array.from(codes);
}

// To generate new codes, run this script with `npx tsx accessCodes.ts` and replace ACTIVE_OE_ACCESS_CODES.
if (typeof require !== 'undefined' && require.main === module) {
  console.log(generateAccessCodes(35, 8));
}
