import React from 'react';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import { ArticleData, ArticleSection } from '../../types';
import {
  DEFAULT_ARTICLE_DATA,
  getUniqueCitations,
  isRichCitation,
} from '../../utils';
import { Callout } from './Callout';
import { pasteRich } from '../../copypaste';
import getArticleRichText from '@xyla/openevidence-shared-functionality/src/article/copypaste';
import {
  ContentCopyOutlined,
  GetAppOutlined,
  PrintOutlined,
} from '@mui/icons-material';
import { articleToDocx, downloadDocx } from '../../docx';
import { openPrintWindow } from '../../print';
import * as Sentry from '@sentry/browser';

export interface DocumentPreviewSectionProps {
  children: React.ReactNode;
  sectionData: ArticleSection;
  originalArticleData: ArticleData;
}

export function DocumentPreviewSection({
  children,
  sectionData,
  originalArticleData,
}: DocumentPreviewSectionProps) {
  const title = sectionData.section_title;
  const fileName = sectionData.metadata?.file ?? 'download';

  const documentArticleData: ArticleData = {
    ...DEFAULT_ARTICLE_DATA,
    articlesection_set: [sectionData],
  };

  // Determine attribution and citations by reformatting the document's own citations.
  const documentCitations = getUniqueCitations(documentArticleData);

  const attribution = documentCitations.length > 0 ? 'References' : undefined;
  const calloutCitedDocuments = documentCitations.map((citation) =>
    isRichCitation(citation)
      ? {
          title: citation.metadata.citation_detail.title,
          authors: citation.metadata.citation_detail.authors_string,
          publication_info:
            citation.metadata.citation_detail.publication_info_string ??
            undefined,
          href: citation.metadata.citation_detail.href,
        }
      : { title: citation }
  );

  // Action definitions. Will appear as buttons in the header.
  const copyToClipboardAction = () => {
    const references = getUniqueCitations(documentArticleData);
    const tableReferences = getUniqueCitations(originalArticleData);
    const richText = getArticleRichText(
      documentArticleData,
      references,
      (warning: string) =>
        Sentry.withScope((scope) => {
          scope.setTag('xyla.error_source', 'tables');
          scope.setLevel('warning');
          Sentry.captureMessage(warning);
        }),
      tableReferences
    );
    pasteRich(richText, richText);
  };

  const downloadDocxAction = () => {
    const tableCitationOrdering = getUniqueCitations(originalArticleData);
    const docx = articleToDocx(documentArticleData, tableCitationOrdering);
    downloadDocx(docx, fileName);
  };

  const printAction = () => {
    openPrintWindow(documentArticleData);
  };

  return (
    <Callout
      title={title}
      icon={<ArticleOutlined />}
      actions={[
        {
          icon: <ContentCopyOutlined />,
          onClick: copyToClipboardAction,
          tooltipText: 'Copy to clipboard',
          snackbarMessage: 'Copied to clipboard',
        },
        {
          icon: <GetAppOutlined />,
          onClick: downloadDocxAction,
          tooltipText: 'Download .docx',
          snackbarMessage: 'Downloaded .docx file',
        },
        {
          icon: <PrintOutlined />,
          onClick: printAction,
          tooltipText: 'Print',
          snackbarMessage: 'Opened print window',
        },
      ]}
      headerSx={{
        color: 'grey.900',
        backgroundColor: 'grey.200',
      }}
      attribution={attribution}
      documents={calloutCitedDocuments}
    >
      {children}
    </Callout>
  );
}
