import { ASKOE__ALLOW_HIPAA_EXPERIENCE } from '../featureFlags';
import { UserInfo } from '../users/types';
import { Waffle } from '../waffle/useWaffle';

export function baaBannerEnabledClientSide(
  waffle: Waffle,
  userData: UserInfo | null,
  country: string
) {
  // When the feature flag is active, the user has not signed the BAA, and the user is in the US, show the banner
  return (
    waffle.is_flag_active(ASKOE__ALLOW_HIPAA_EXPERIENCE) &&
    userData !== null &&
    userData?.isBAASigned !== true &&
    country === 'US'
  );
}

export interface DismissData {
  id: string;
  timestamp: string;
  dismissCount: number;
}

export const BAA_BANNER_ID = 'HIPAA_BAA_BANNER';
export const BAA_BANNER_DISMISS_STORAGE_KEY = 'baaBannerDismissedData';
export const BAA_BANNER_ACCEPTED_STORAGE_KEY = 'baaBannerAccepted';
export const BAA_BANNER_MAX_DISMISS_TIMES = 3; // Show banner for up to 3 times if only dismissed

/**
 * Creates an updated dismissal data object based on current data
 *
 * @param currentData - The current dismissal data as a string or null if none exists
 * @param bannerId - The ID of the banner being dismissed (defaults to BANNER_ID)
 * @returns A new DismissData object with updated information
 */
export function createDismissData(
  currentData: string | null,
  bannerId: string = BAA_BANNER_ID
): DismissData {
  let dismissCount = 0;

  // Parse existing data if available
  if (currentData) {
    try {
      const data = JSON.parse(currentData) as Partial<DismissData>;
      // Only increment count if it's the same banner ID
      if (data.id === bannerId && typeof data.dismissCount === 'number') {
        dismissCount = data.dismissCount + 1;
      }
    } catch (error) {
      console.error('Error parsing dismissal data:', error);
    }
  }

  // Return the updated dismissal data
  return {
    id: bannerId,
    timestamp: new Date().toISOString(),
    dismissCount: dismissCount,
  };
}

// BAA Banner Error Message
export const BAA_BANNER_ERROR_MESSAGE = `Error accepting Business Associate Agreement, please try again later.`;

// BAA Banner Text Copy
export const HIPAA_COMPLIANCE_STATEMENT = 'OpenEvidence is HIPAA compliant.';
export const BAA_BANNER_NOTICE =
  'If HIPAA applies to you, review and accept our';
export const BAA_BANNER_LINK_TEXT = 'Business Associate Agreement';
export const BAA_BANNER_SUFFIX =
  'to begin including PHI in your conversations.';
