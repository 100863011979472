import { ArticleData, Citation, RichCitation, CitationDetail } from './types';

/** Returns the full citation content as a single string */
export function getPlainCitationString(reference: Citation): string {
  if (typeof reference === 'string') {
    return reference;
  } else {
    return reference.citation;
  }
}

/** Array.prototype.findIndex for Citation arrays */
export function findCitationIndex(arr: Citation[], citation: Citation): number {
  const searchString = getPlainCitationString(citation);
  return arr.findIndex((el) => getPlainCitationString(el) === searchString);
}

export function isRichCitation(reference: Citation): reference is RichCitation {
  return (
    typeof reference !== 'string' && Boolean(reference.metadata.citation_detail)
  );
}

export function addTrackingParamsToReferenceLink(detail: CitationDetail) {
  try {
    // add on UTM parameters for tracking of NEJM links
    // if we want to do anything similar for other publishers, we can add more cases here
    const url = new URL(detail.href);
    if (url.hostname === 'www.nejm.org') {
      url.searchParams.set('utm_source', 'openevidence');
      return url.toString();
    }
    return detail.href;
  } catch {
    // always fail back to href just in case but log an error
    console.log(
      'error adding tracking params to reference link: ',
      detail.href
    );
    return detail.href;
  }
}

/** Dedupe citations in article and build the corresponding references list in order. */
export function buildReferencesListFromArticle(
  article: ArticleData
): Citation[] {
  const references: Citation[] = [];

  // "Upsert" the citation into the references list
  function processCitation(citation: Citation) {
    const citationIndex = findCitationIndex(references, citation);
    if (citationIndex === -1) {
      // Compute snippet text with highlights
      const normalizedCitation: Citation =
        isRichCitation(citation) && citation.metadata.snippet_text
          ? {
              ...citation,
              all_snippet_display_data: [
                {
                  text: citation.metadata.snippet_text,
                  critical_sentences: citation.metadata.critical_sentences,
                  image_data: citation.metadata.citation_detail.image_data,
                },
              ],
            }
          : citation;

      references.push(normalizedCitation);
    } else {
      // We've already found this citation.
      // If it is a rich citation that has different snippet text, add it to the list of snippet text
      const existingCitation = references[citationIndex];
      let newCitation: Citation = existingCitation;
      if (isRichCitation(citation) && isRichCitation(existingCitation)) {
        // Check if the snippet text is already in the list
        const newSnippetText = citation.metadata.snippet_text;
        if (newSnippetText) {
          const newCriticalSentences = citation.metadata.critical_sentences;
          if (
            !existingCitation.all_snippet_display_data?.some(
              (snippet) => snippet.text === newSnippetText
            )
          ) {
            // The snippet text is not already in the list or the list does not exist
            const snippetDisplayData = {
              text: newSnippetText,
              critical_sentences: newCriticalSentences,
              image_data: citation.metadata.citation_detail.image_data,
            };

            // Make the new list
            const newSnippetDisplayDataArray =
              existingCitation.all_snippet_display_data
                ? [
                    ...existingCitation.all_snippet_display_data,
                    snippetDisplayData,
                  ]
                : [snippetDisplayData];

            // Make the new citation
            newCitation = {
              ...existingCitation,
              all_snippet_display_data: newSnippetDisplayDataArray,
            };
          }
        }

        // Update the existing citation
        references[citationIndex] = newCitation;
      }
    }
  }

  for (let section of article.articlesection_set) {
    for (let paragraph of section.articleparagraph_set) {
      for (let articleSpan of paragraph.articlespan_set) {
        for (let citation of articleSpan.citations) {
          processCitation(citation);
        }
      }
    }
  }

  return references;
}
