import type {
  ArticleData,
  ArticleFigure,
  ArticleSection,
  ArticleSpan,
  Citation,
} from './types';
import { SxProps } from '@mui/system';
import type { Updater } from 'use-immer';
import { buildReferencesListFromArticle } from './citations';
import React from 'react';

/** If a section identifier is included here, we should proceed with the rendering pipeline even if the paragraph set is empty. */
const SHOULD_RENDER_SECTION_IDENTIFIER_IF_EMPTY = [
  'calculator',
  'calculator-unsupported',
];

// Same with paragraph idenfiers
const SHOULD_RENDER_PARAGRAPH_IDENTIFIER_IF_EMPTY = ['interpretation'];

interface ArticleComponentProps {
  data: any;
  styles: Record<string, string>;
  conjoined_page?: boolean;
  conjoined_page_first_page?: boolean;
  [key: string]: any;
}

export type RenderFunctionFilter = (
  _: ArticleComponentProps
) => JSX.Element | null;

interface RenderProps {
  /** The list of references cited in this span */
  references: Citation[];
  /** The identifier of the section that this span belongs to */
  sectionIdentifier?: string;
  styles: any;
  onCitationPress?: (ref: Citation) => void;
  additionalComponentData: Record<string, any>;
  firstTextSpanFound?: { found: boolean };
  smallVersion?: boolean;
  cursorSxFor?: (location: string) => any;
  questionIndex?: number;
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  disableCitationLinks?: boolean;
}

export type ArticleDataUpdater =
  | Updater<ArticleData>
  | Updater<ArticleData | null>;

export type SpanRenderer = (
  props: RenderProps & {
    articleSpan: ArticleSpan;
    prefix: string;
  }
) => JSX.Element | null;

export type FigureRenderer = (
  props: RenderProps & {
    articleFigure: ArticleFigure;
    index: number;
    j: number;
    k: number;
  }
) => JSX.Element | null;

export interface SectionContainerProps {
  children: JSX.Element[];
  styles: any;
  sectionData: ArticleSection;
  sectionIndex: number;
  articleData: ArticleData;
  setArticleData?: ArticleDataUpdater;
  questionIndex?: number;
  conversationLength?: number;
  sectionTitleElement: React.ReactNode | null;
  sectionImageElement: React.ReactNode | null;
  sectionClassName?: string;
}

export interface ParagraphContainerProps {
  children: React.ReactNode;
  title?: string;
  styles: any;
  paragraphTitleSx?: any;
  captionSpans?: JSX.Element[];
}

export interface BiblographyProps {
  [key: string]: any;
}

export interface SectionTitleProps {
  titleKey: string;
  title: string;
  name: string;
  styles: any;
  index: number;
  cursorSxFor?: (location: string) => any;
}

export interface SectionImageProps {
  image: string;
  styles: any;
}

export interface SectionSkeletonProps {
  sectionClassName: string;
  sectionBodyClassName: string;
}

export interface ArticleRenderers {
  // render functions (stateless)
  renderSpan: SpanRenderer;
  renderFigure?: FigureRenderer;

  // components (maybe stateful)
  SectionContainer: React.FC<SectionContainerProps>;
  ParagraphContainer: React.FC<ParagraphContainerProps>;

  // optional/legacy components
  FigureParagraphContainer?: React.FC<ParagraphContainerProps>;
  Bibliography?: React.FC<BiblographyProps>;
  SectionTitle?: React.FC<SectionTitleProps>;
  SectionImage?: React.FC<SectionImageProps>;
  SectionSkeleton?: React.FC<SectionSkeletonProps>;
}

export type ArticleRenderersWithFigure = ArticleRenderers & {
  renderFigure: FigureRenderer;
  FigureParagraphContainer: React.FC<ParagraphContainerProps>;
};

export function processParagraph({
  sectionData,
  index,
  references,
  notableStudyData,
  styles,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
  firstTextSpanFound,
  smallVersion,
  cursorSxFor,
  questionIndex,
  renderers,
  onCitationPress,
}: {
  sectionData: ArticleSection;
  index: number;
  references: Citation[];
  notableStudyData: Record<string, unknown>;
  styles: Record<string, string>;
  disableCitationLinks?: boolean;
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  additionalComponentData: Record<string, unknown>;
  firstTextSpanFound: { found: boolean };
  smallVersion: boolean;
  cursorSxFor: (_location: string) => SxProps;
  questionIndex?: number;
  renderers: ArticleRenderers;
  onCitationPress?: (ref: Citation) => void;
}) {
  const { renderSpan, ParagraphContainer, Bibliography } = renderers;

  const paragraphsInSection: Record<number, JSX.Element> = {};
  // Get the normal text paragraphs
  for (let j = 0; j < sectionData.articleparagraph_set.length; j++) {
    let paragraphData = sectionData.articleparagraph_set[j];
    let orderInSection = paragraphData.order_in_section;
    // Handle the old version where there's no order_in_section
    if (paragraphData.order_in_section === undefined) {
      orderInSection = j;
    }

    // If it's a special paragraph, handle separately
    let paragraph = null;
    if (paragraphData.identifier === 'notable_studies') {
      paragraph = Bibliography ? (
        <Bibliography {...notableStudyData} in_article_version={true} />
      ) : null;
    } else {
      // All spans in a paragraph
      let spansInParagraph = [];

      for (let k = 0; k < paragraphData.articlespan_set.length; k++) {
        let articleSpan = paragraphData.articlespan_set[k];
        let span_element = renderSpan({
          articleSpan,
          references,
          sectionIdentifier: sectionData.identifier,
          prefix: `${index}-${j}-${k}`,
          questionIndex,
          styles,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData,
          firstTextSpanFound,
          smallVersion,
          cursorSxFor: (location: string) =>
            cursorSxFor(
              `articleparagraph_set.${j}.articlespan_set.${k}.${location}`
            ),
          onCitationPress,
        });
        if (span_element) {
          spansInParagraph.push(span_element);
        }
      }

      const paragraphTitleSx = cursorSxFor(
        `articleparagraph_set.${j}.paragraph_title`
      );

      const paragraphTitle =
        paragraphData.identifier === 'interpretation'
          ? paragraphData.paragraph_title
              .replace('Computed value for ', '')
              .replace('Computed calculator value: ', '')
          : paragraphData.paragraph_title;

      const forceIncludeParagraph =
        SHOULD_RENDER_PARAGRAPH_IDENTIFIER_IF_EMPTY.includes(
          paragraphData.identifier
        );

      // If the article is a quotation/snippet, handle things differently
      paragraph =
        spansInParagraph.length > 0 || forceIncludeParagraph ? (
          <ParagraphContainer
            key={`${index}-${orderInSection}`}
            // identifier for calculators
            data-identifier={paragraphData.identifier}
            title={paragraphTitle}
            styles={styles}
            paragraphTitleSx={paragraphTitleSx}
          >
            {spansInParagraph}
          </ParagraphContainer>
        ) : null;
    }

    if (paragraph) {
      paragraphsInSection[orderInSection] = paragraph;
    }
  }
  return paragraphsInSection;
}

function processFigureParagraphs({
  sectionData,
  index,
  references,
  styles,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
  renderers,
  onCitationPress,
}: {
  sectionData: ArticleSection;
  index: number;
  references: Citation[];
  styles: Record<string, string>;
  disableCitationLinks?: boolean;
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  additionalComponentData: Record<string, unknown>;
  renderers: ArticleRenderersWithFigure;
  onCitationPress?: (ref: Citation) => void;
}) {
  const { renderFigure, renderSpan, FigureParagraphContainer } = renderers;

  const paragraphsInSection: Record<number, JSX.Element> = {};

  // Get the figure paragraphs
  if (sectionData.articlefigureparagraph_set !== undefined) {
    for (let j = 0; j < sectionData.articlefigureparagraph_set.length; j++) {
      let paragraphData = sectionData.articlefigureparagraph_set[j];
      let order_in_section = paragraphData.order_in_section;
      // Handle the old version where there's no order_in_section
      if (paragraphData.order_in_section === undefined) {
        order_in_section = j;
      }

      // All figures in a figure paragraph
      let figuresInParagraph = [];

      for (let k = 0; k < paragraphData.articlefigure_set.length; k++) {
        let articleFigure = paragraphData.articlefigure_set[k];
        let figure_element = renderFigure({
          articleFigure,
          references,
          index,
          j,
          k,
          styles,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData,
        });
        if (figure_element != null) figuresInParagraph.push(figure_element);
      }

      if (figuresInParagraph.length === 0) {
        delete paragraphsInSection[order_in_section];
        continue;
      }

      const captionSpans =
        paragraphData.captionspan_set
          ?.map((articleSpan, spanIndex) =>
            renderSpan({
              articleSpan,
              references,
              prefix: `${index}-${j}-${1000000 + spanIndex}`,
              styles,
              disableCitationLinks,
              additionalRenderFunctions,
              additionalComponentData,
              onCitationPress,
            })
          )
          ?.filter(
            // FIXME
            /* eslint-disable-next-line no-loop-func */
            (spanElement): spanElement is JSX.Element => spanElement !== null
          ) ?? [];

      paragraphsInSection[order_in_section] = (
        <FigureParagraphContainer
          title={paragraphData.paragraph_title}
          styles={styles}
          captionSpans={captionSpans}
        >
          {figuresInParagraph}
        </FigureParagraphContainer>
      );
    }
  }
  return paragraphsInSection;
}

interface DeriveGeneratedArticleBaseProps {
  data: ArticleData;
  linesToSkip?: number;
  notableStudyData?: Record<string, unknown>;
  numConditions?: number;
  numInterventions?: number;
  styles: Record<string, string>;
  disableCitationLinks?: boolean;
  additionalRenderFunctions?: Record<string, RenderFunctionFilter>;
  additionalComponentData?: Record<string, unknown>;
  smallVersion?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
  questionIndex?: number;
  conversationLength?: number;
  /** Updater fn passed in from parent component, since we can't use a react hook here */
  setArticleData?: ArticleDataUpdater;
  /** Renderers compatible with target platform */
  renderers: ArticleRenderers;
  slugify?: (s: string) => string;
  onCitationPress?: (ref: Citation) => void;
}

export default function deriveGeneratedArticleBase({
  data,
  linesToSkip = 0,
  notableStudyData = { sections: undefined },
  numConditions,
  numInterventions,
  styles,
  disableCitationLinks,
  additionalRenderFunctions = {},
  additionalComponentData = {},
  smallVersion = false,
  cursorSxFor = () => ({}),
  questionIndex,
  conversationLength,
  setArticleData,
  renderers,
  onCitationPress,
  slugify,
}: DeriveGeneratedArticleBaseProps) {
  const {
    SectionSkeleton,
    SectionContainer,
    SectionTitle,
    SectionImage,
    renderFigure,
  } = renderers;

  // Eagerly build the references list. Citations need this to display the correct number (e.g. [1])
  const references: Citation[] = buildReferencesListFromArticle(data);

  const generatedArticle = [];
  const sectionTitles: [string, string, string][] = []; // type, slug, string
  let keyFindingsSection = null;
  const image_section = 2;

  const firstTextSpanFound = { found: false };

  for (let i = 1 + linesToSkip; i < data.articlesection_set.length; i++) {
    let sectionData = data.articlesection_set[i];

    // Set the classnames, because it's needed for both the section content and the placeholder
    let sectionStyleName = '';
    numInterventions = numInterventions ?? 0;
    numConditions = numConditions ?? 0;
    if (numInterventions === 1 && numConditions === 0) {
      sectionStyleName = styles.section_int;
    } else if (numInterventions === 1 && numConditions === 1) {
      sectionStyleName = styles.section_ic;
    } else if (numInterventions === 0 && numConditions === 1) {
      sectionStyleName = styles.section_cond;
    }

    let additionalClasses =
      data.articlesection_set[i].identifier === 'hide-on-print'
        ? ' hide-on-print'
        : '';

    let sectionClassName = `${styles.section} ${sectionStyleName} ${additionalClasses}`;
    let sectionBodyClassName = styles.section_body;

    // Short circuit if it's a placeholder section
    if (sectionData.placeholder) {
      const placeholder = SectionSkeleton && (
        <SectionSkeleton
          sectionClassName={sectionClassName}
          sectionBodyClassName={sectionBodyClassName}
        />
      );
      generatedArticle.push(placeholder);
      continue;
    }

    let paragraphsInSection: Record<number, JSX.Element> = {};

    Object.assign(
      paragraphsInSection,
      processParagraph({
        renderers,
        sectionData,
        index: i,
        references,
        notableStudyData,
        styles,
        disableCitationLinks,
        additionalRenderFunctions,
        additionalComponentData,
        firstTextSpanFound,
        smallVersion,
        cursorSxFor: (location: string) =>
          cursorSxFor(`articlesection_set.${i}.${location}`),
        questionIndex,
        onCitationPress,
      })
    );

    if (renderFigure !== undefined) {
      // Even in the small version we want to show any figure paragraphs
      // NOTE: There can be figures in the "normal" text paragraphs too
      Object.assign(
        paragraphsInSection,
        processFigureParagraphs({
          renderers: renderers as ArticleRenderersWithFigure,
          sectionData,
          index: i,
          references,
          styles,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData,
          onCitationPress,
        })
      );
    }

    // Order everything into a normal list
    const paragraphsInSectionList = Object.entries(paragraphsInSection)
      .sort(([indexA], [indexB]) => +indexA - +indexB)
      .map(([_, node]) => node);

    // Skip out before writing the section if there are no paragraphs
    if (
      paragraphsInSectionList.length === 0 &&
      !SHOULD_RENDER_SECTION_IDENTIFIER_IF_EMPTY.includes(
        sectionData.identifier
      )
    )
      continue;

    // Write the section element
    let sectionTitleElement = null;
    if (data.articlesection_set[i].section_title !== '') {
      let sectionTitleString = data.articlesection_set[i].section_title;

      // Get clean version without any html/svgs etc
      const sectionTitleStringClean = sectionTitleString.includes('<')
        ? data.articlesection_set[i].section_title
            .substring(0, sectionTitleString.indexOf('<'))
            .trim()
        : data.articlesection_set[i].section_title.trim();

      const titleName =
        slugify?.(sectionTitleStringClean) ?? sectionTitleStringClean;
      const titleLinkNameBase = data.articlesection_set[i].identifier
        ? data.articlesection_set[i].identifier
        : titleName;
      const titleLinkName = titleLinkNameBase
        ?.toLowerCase()
        ?.replaceAll(' ', '');
      sectionTitleElement = SectionTitle && (
        <SectionTitle
          titleKey={`section-title-${i}`}
          title={sectionTitleString}
          name={titleLinkName}
          styles={styles}
          index={i}
          cursorSxFor={cursorSxFor}
        />
      );
      sectionTitles.push(['section', titleName, sectionTitleStringClean]);
    }

    let sectionImageElement = null;
    if (data.image_url && i === image_section) {
      sectionImageElement = SectionImage && (
        <SectionImage image={data.image_url} styles={styles} />
      );
    }

    const section = (
      <SectionContainer
        key={`${questionIndex}-${sectionData.identifier}-${i}--section`}
        styles={styles}
        sectionData={sectionData}
        sectionIndex={i}
        articleData={data}
        setArticleData={setArticleData}
        questionIndex={questionIndex}
        conversationLength={conversationLength}
        sectionTitleElement={sectionTitleElement}
        sectionImageElement={sectionImageElement}
        sectionClassName={sectionClassName}
      >
        {paragraphsInSectionList}
      </SectionContainer>
    );

    // If it's a special case key findings section, set the state explicitly
    if (data.articlesection_set[i].identifier === 'key_findings') {
      keyFindingsSection = section;
    } else {
      generatedArticle.push(section);
    }
  }

  return {
    keyFindingsSection,
    references,
    generatedArticle,
    sectionTitles,
  };
}
