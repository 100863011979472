import { FieldValues, RegisterOptions } from 'react-hook-form';

export interface Option {
  label: string;
  value: string;
}

export interface FieldMetadata<TFieldValues extends FieldValues> {
  label: string;
  placeholder?: string;
  options?: Option[];
  rules: RegisterOptions<TFieldValues, any>;
}

export enum UploadType {
  MED_STUDENT_VERIFICATION = 'MED_STUDENT_VERIFICATION',
  HCP_VERIFICATION = 'HCP_VERIFICATION',
  QUESTION_ATTACHMENT = 'QUESTION_ATTACHMENT',
}
