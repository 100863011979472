import { v4 as uuidv4 } from 'uuid';
import { NPI_ACCESS_ROLE_NAME, VIP_ACCESS_ROLE_NAME, RawFile } from '../types';
import { Waffle } from '../waffle/useWaffle';
import { UserInfo } from '../users/types';
import { ASKOE__ALLOW_ATTACHMENTS } from '../featureFlags';

export const VALID_FILE_TYPES: string[] = [
  // this list might be fragile depending on the OS
  // keep in sync with src/xyla-cloud-functions/attachment-parser/main.py
  'text/csv',
  'text/html',
  'text/plain',
  'text/xml',
  'application/json',
  'application/xml',
  // word documents
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // excel spreadsheets
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // pdf
  'application/pdf',
];

interface Attachment<FileType extends File | RawFile> {
  // UUID
  id: string;
  // logical name of the file
  name: string;
  // information parsed on backend
  content?: string;
  format?: string;
  // temporary state to handle actual file upload
  uploadInProgress?: boolean;
  rawFile?: FileType;
}

export interface AppAttachment extends Attachment<RawFile> {}
export interface WebAttachment extends Attachment<File> {}

const MAX_NUM_FILES_TO_UPLOAD = 5;
// KEEP IN SYNC WITH src/xyla-cloud-functions/attachment-parser/main.py
const MAX_FILE_SIZE_IN_MB = 2;
export const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
export const fileSizeTooLargeErrorMessage = (name: string) =>
  `${name} is too large, the maximum file size allowed is ${MAX_FILE_SIZE_IN_MB} MB`;
export const invalidFileTypeErrorMessage = (name: string) =>
  `${name} is not an allowed file type. Only .pdf, .doc(x), .xls(x), and plain text files are accepted`;

export type SetAttachmentsFunc<GenericAttachment> = React.Dispatch<
  React.SetStateAction<GenericAttachment[]>
>;

export function removeAttachment<
  GenericAttachment extends AppAttachment | WebAttachment
>(
  id: string,
  attachments: GenericAttachment[],
  setAttachments: SetAttachmentsFunc<GenericAttachment>
) {
  setAttachments(attachments.filter((attachment) => attachment.id !== id));
}

export async function onFileSelected<FileType extends RawFile | File>(
  acceptedFiles: FileType[],
  attachments: Attachment<FileType>[],
  setAttachments: SetAttachmentsFunc<Attachment<FileType>>,
  errors: string[],
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,
  uploadAttachmentToGcs: (args: {
    file: FileType;
    uploadFileName: string;
  }) => Promise<void>
) {
  const acceptedAttachments = acceptedFiles.map((rawFile) => ({
    id: uuidv4(),
    uploadInProgress: true,
    name: rawFile.name,
    rawFile: rawFile,
  }));
  if (
    acceptedAttachments.length + attachments.length >
    MAX_NUM_FILES_TO_UPLOAD
  ) {
    acceptedAttachments.splice(MAX_NUM_FILES_TO_UPLOAD - attachments.length);
    const newError = `Maximum number of files exceeded, only ${MAX_NUM_FILES_TO_UPLOAD} files are allowed per question`;
    setErrors(errors.concat([newError]));
  }
  setAttachments(attachments.concat(acceptedAttachments));
  await Promise.all(
    acceptedAttachments?.map(async (attachment: Attachment<FileType>) => {
      try {
        await uploadAttachmentToGcs?.({
          file: attachment.rawFile as FileType,
          uploadFileName: attachment.id,
        });
        // use function setter to handle asynchronicity
        setAttachments((lastAttachments: Attachment<FileType>[]) =>
          lastAttachments.map((candidate) =>
            candidate.id === attachment.id
              ? {
                  ...candidate,
                  uploadInProgress: false,
                  // clear out rawFile just for consistency since we shouldn't need it anymore
                  rawFile: undefined,
                }
              : candidate
          )
        );
      } catch {
        // use function setter to handle asynchronicity
        setAttachments((lastAttachments: Attachment<FileType>[]) =>
          lastAttachments.filter((candidate) => candidate.id !== attachment.id)
        );
        setErrors(
          errors.concat([
            `Unable to upload ${attachment.name}. Please check your connection and try again.`,
          ])
        );
      }
    })
  );
}

export function shouldTruncateFileName(
  name: string,
  maxCharsPerFileName: number
) {
  return name.length >= maxCharsPerFileName;
}

export function truncateFileNameIfNecessary(
  name: string,
  maxCharsPerFileName: number
) {
  if (!shouldTruncateFileName(name, maxCharsPerFileName)) {
    return name;
  }
  // just cut out the middle so e.g. if it's max 30 chars
  // take the first 14, 3 for the ellipsis, last 13
  const halfMaxLength = Math.floor(maxCharsPerFileName / 2);
  return (
    name.substring(0, halfMaxLength - 1) +
    '...' +
    name.substring(name.length - (halfMaxLength - 2))
  );
}

export function attachmentsEnabledClientSide(
  waffle: Waffle,
  userData: UserInfo | null,
  country: string
) {
  return (
    waffle.is_flag_active(ASKOE__ALLOW_ATTACHMENTS) &&
    [NPI_ACCESS_ROLE_NAME, VIP_ACCESS_ROLE_NAME].includes(
      userData?.oeAccessLevel ?? ''
    ) &&
    country === 'US'
  );
}
