/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Allergy & Immunology` - Allergy Immunology
 * * `Anesthesiology` - Anesthesiology
 * * `Cardiology` - Cardiology
 * * `Critical Care` - Critical Care
 * * `Dermatology` - Dermatology
 * * `Emergency Medicine` - Emergency Medicine
 * * `Endocrinology` - Endocrinology
 * * `Family Medicine` - Family Medicine
 * * `Gastroenterology` - Gastroenterology
 * * `Genetics` - Genetics
 * * `Geriatrics` - Geriatrics
 * * `HIV / AIDS` - Hiv Aids
 * * `Hospital-Based Medicine` - Hospital Based Medicine
 * * `Infectious Disease` - Infectious Disease
 * * `Internal Medicine` - Internal Medicine
 * * `Nephrology` - Nephrology
 * * `Neurology` - Neurology
 * * `Nursing` - Nursing
 * * `OB / GYN` - Ob Gyn
 * * `Oncology / Hematology` - Oncology Hematology
 * * `Ophthalmology` - Ophthalmology
 * * `Orthopedics` - Orthopedics
 * * `Otolaryngology` - Otolaryngology
 * * `Pain Management` - Pain Management
 * * `Pathology` - Pathology
 * * `Pediatrics` - Pediatrics
 * * `Pharmacy` - Pharmacy
 * * `Primary Care` - Primary Care
 * * `Psychiatry` - Psychiatry
 * * `Pulmonology` - Pulmonology
 * * `Radiology` - Radiology
 * * `Rheumatology` - Rheumatology
 * * `Surgery` - Surgery
 * * `Transplantation` - Transplantation
 * * `Urology` - Urology
 * * `Other` - Other
 * @export
 */
export const SpecialtyEnum = {
    AllergyImmunology: 'Allergy & Immunology',
    Anesthesiology: 'Anesthesiology',
    Cardiology: 'Cardiology',
    CriticalCare: 'Critical Care',
    Dermatology: 'Dermatology',
    EmergencyMedicine: 'Emergency Medicine',
    Endocrinology: 'Endocrinology',
    FamilyMedicine: 'Family Medicine',
    Gastroenterology: 'Gastroenterology',
    Genetics: 'Genetics',
    Geriatrics: 'Geriatrics',
    HivAids: 'HIV / AIDS',
    HospitalBasedMedicine: 'Hospital-Based Medicine',
    InfectiousDisease: 'Infectious Disease',
    InternalMedicine: 'Internal Medicine',
    Nephrology: 'Nephrology',
    Neurology: 'Neurology',
    Nursing: 'Nursing',
    ObGyn: 'OB / GYN',
    OncologyHematology: 'Oncology / Hematology',
    Ophthalmology: 'Ophthalmology',
    Orthopedics: 'Orthopedics',
    Otolaryngology: 'Otolaryngology',
    PainManagement: 'Pain Management',
    Pathology: 'Pathology',
    Pediatrics: 'Pediatrics',
    Pharmacy: 'Pharmacy',
    PrimaryCare: 'Primary Care',
    Psychiatry: 'Psychiatry',
    Pulmonology: 'Pulmonology',
    Radiology: 'Radiology',
    Rheumatology: 'Rheumatology',
    Surgery: 'Surgery',
    Transplantation: 'Transplantation',
    Urology: 'Urology',
    Other: 'Other'
} as const;
export type SpecialtyEnum = typeof SpecialtyEnum[keyof typeof SpecialtyEnum];


export function instanceOfSpecialtyEnum(value: any): boolean {
    for (const key in SpecialtyEnum) {
        if (Object.prototype.hasOwnProperty.call(SpecialtyEnum, key)) {
            if (SpecialtyEnum[key as keyof typeof SpecialtyEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SpecialtyEnumFromJSON(json: any): SpecialtyEnum {
    return SpecialtyEnumFromJSONTyped(json, false);
}

export function SpecialtyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialtyEnum {
    return json as SpecialtyEnum;
}

export function SpecialtyEnumToJSON(value?: SpecialtyEnum | null): any {
    return value as any;
}

export function SpecialtyEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SpecialtyEnum {
    return value as SpecialtyEnum;
}

