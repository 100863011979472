/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DBIdentificationRequest } from './DBIdentificationRequest';
import {
    DBIdentificationRequestFromJSON,
    DBIdentificationRequestFromJSONTyped,
    DBIdentificationRequestToJSON,
    DBIdentificationRequestToJSONTyped,
} from './DBIdentificationRequest';
import type { SpecialtyEnum } from './SpecialtyEnum';
import {
    SpecialtyEnumFromJSON,
    SpecialtyEnumFromJSONTyped,
    SpecialtyEnumToJSON,
    SpecialtyEnumToJSONTyped,
} from './SpecialtyEnum';
import type { RegistrationTypeEnum } from './RegistrationTypeEnum';
import {
    RegistrationTypeEnumFromJSON,
    RegistrationTypeEnumFromJSONTyped,
    RegistrationTypeEnumToJSON,
    RegistrationTypeEnumToJSONTyped,
} from './RegistrationTypeEnum';
import type { HcpIdentifierTypeEnum } from './HcpIdentifierTypeEnum';
import {
    HcpIdentifierTypeEnumFromJSON,
    HcpIdentifierTypeEnumFromJSONTyped,
    HcpIdentifierTypeEnumToJSON,
    HcpIdentifierTypeEnumToJSONTyped,
} from './HcpIdentifierTypeEnum';

/**
 * 
 * @export
 * @interface PatchedOEUserProfileRequest
 */
export interface PatchedOEUserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    auth0Id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    name?: string | null;
    /**
     * 
     * @type {RegistrationTypeEnum}
     * @memberof PatchedOEUserProfileRequest
     */
    registrationType?: RegistrationTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    hasProcessedUser?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    referrer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    inviteCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    hasConsentedToPolicies?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    isBAASigned?: boolean | null;
    /**
     * 
     * @type {HcpIdentifierTypeEnum}
     * @memberof PatchedOEUserProfileRequest
     */
    hcpIdentifierType?: HcpIdentifierTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    hcpIdentifierValue?: string | null;
    /**
     * 
     * @type {SpecialtyEnum}
     * @memberof PatchedOEUserProfileRequest
     */
    specialty?: SpecialtyEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    identifiedInDB?: boolean | null;
    /**
     * 
     * @type {DBIdentificationRequest}
     * @memberof PatchedOEUserProfileRequest
     */
    userDataFromDB?: DBIdentificationRequest;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    db_occupation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    db_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    db_eid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    db_specialty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    db_npi?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    medicalSchool?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    expectedYearOfGraduation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    expectedMonthOfGraduationString?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    hasVerificationFiles?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    occupation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    role?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    patientCommunity?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOEUserProfileRequest
     */
    askedDoctor?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOEUserProfileRequest
     */
    patientCommunitySubtype?: string | null;
}



/**
 * Check if a given object implements the PatchedOEUserProfileRequest interface.
 */
export function instanceOfPatchedOEUserProfileRequest(value: object): value is PatchedOEUserProfileRequest {
    return true;
}

export function PatchedOEUserProfileRequestFromJSON(json: any): PatchedOEUserProfileRequest {
    return PatchedOEUserProfileRequestFromJSONTyped(json, false);
}

export function PatchedOEUserProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedOEUserProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'auth0Id': json['auth0Id'] == null ? undefined : json['auth0Id'],
        'email': json['email'] == null ? undefined : json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'registrationType': json['registrationType'] == null ? undefined : RegistrationTypeEnumFromJSON(json['registrationType']),
        'hasProcessedUser': json['hasProcessedUser'] == null ? undefined : json['hasProcessedUser'],
        'referrer': json['referrer'] == null ? undefined : json['referrer'],
        'inviteCode': json['inviteCode'] == null ? undefined : json['inviteCode'],
        'hasConsentedToPolicies': json['hasConsentedToPolicies'] == null ? undefined : json['hasConsentedToPolicies'],
        'isBAASigned': json['isBAASigned'] == null ? undefined : json['isBAASigned'],
        'hcpIdentifierType': json['hcpIdentifierType'] == null ? undefined : HcpIdentifierTypeEnumFromJSON(json['hcpIdentifierType']),
        'hcpIdentifierValue': json['hcpIdentifierValue'] == null ? undefined : json['hcpIdentifierValue'],
        'specialty': json['specialty'] == null ? undefined : SpecialtyEnumFromJSON(json['specialty']),
        'identifiedInDB': json['identifiedInDB'] == null ? undefined : json['identifiedInDB'],
        'userDataFromDB': json['userDataFromDB'] == null ? undefined : DBIdentificationRequestFromJSON(json['userDataFromDB']),
        'db_occupation': json['db_occupation'] == null ? undefined : json['db_occupation'],
        'db_name': json['db_name'] == null ? undefined : json['db_name'],
        'db_eid': json['db_eid'] == null ? undefined : json['db_eid'],
        'db_specialty': json['db_specialty'] == null ? undefined : json['db_specialty'],
        'db_npi': json['db_npi'] == null ? undefined : json['db_npi'],
        'medicalSchool': json['medicalSchool'] == null ? undefined : json['medicalSchool'],
        'expectedYearOfGraduation': json['expectedYearOfGraduation'] == null ? undefined : json['expectedYearOfGraduation'],
        'expectedMonthOfGraduationString': json['expectedMonthOfGraduationString'] == null ? undefined : json['expectedMonthOfGraduationString'],
        'hasVerificationFiles': json['hasVerificationFiles'] == null ? undefined : json['hasVerificationFiles'],
        'occupation': json['occupation'] == null ? undefined : json['occupation'],
        'role': json['role'] == null ? undefined : json['role'],
        'patientCommunity': json['patientCommunity'] == null ? undefined : json['patientCommunity'],
        'askedDoctor': json['askedDoctor'] == null ? undefined : json['askedDoctor'],
        'patientCommunitySubtype': json['patientCommunitySubtype'] == null ? undefined : json['patientCommunitySubtype'],
    };
}

export function PatchedOEUserProfileRequestToJSON(json: any): PatchedOEUserProfileRequest {
    return PatchedOEUserProfileRequestToJSONTyped(json, false);
}

export function PatchedOEUserProfileRequestToJSONTyped(value?: PatchedOEUserProfileRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'auth0Id': value['auth0Id'],
        'email': value['email'],
        'name': value['name'],
        'registrationType': RegistrationTypeEnumToJSON(value['registrationType']),
        'hasProcessedUser': value['hasProcessedUser'],
        'referrer': value['referrer'],
        'inviteCode': value['inviteCode'],
        'hasConsentedToPolicies': value['hasConsentedToPolicies'],
        'isBAASigned': value['isBAASigned'],
        'hcpIdentifierType': HcpIdentifierTypeEnumToJSON(value['hcpIdentifierType']),
        'hcpIdentifierValue': value['hcpIdentifierValue'],
        'specialty': SpecialtyEnumToJSON(value['specialty']),
        'identifiedInDB': value['identifiedInDB'],
        'userDataFromDB': DBIdentificationRequestToJSON(value['userDataFromDB']),
        'db_occupation': value['db_occupation'],
        'db_name': value['db_name'],
        'db_eid': value['db_eid'],
        'db_specialty': value['db_specialty'],
        'db_npi': value['db_npi'],
        'medicalSchool': value['medicalSchool'],
        'expectedYearOfGraduation': value['expectedYearOfGraduation'],
        'expectedMonthOfGraduationString': value['expectedMonthOfGraduationString'],
        'hasVerificationFiles': value['hasVerificationFiles'],
        'occupation': value['occupation'],
        'role': value['role'],
        'patientCommunity': value['patientCommunity'],
        'askedDoctor': value['askedDoctor'],
        'patientCommunitySubtype': value['patientCommunitySubtype'],
    };
}

