// Calculator.tsx
import { useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { OmniCalculator } from './OmniCalculator';
import type { CalculatorData, CalculatorValues } from './types';
import type { DraftFunction } from 'use-immer';

// NB(andy): Be very careful importing from @xyla/article. OE App depends on
// @xyla/calculators package, and if we import runtime code here from
// @xyla/article, the resulting dependency tree causes issues with React Native.
// Importing only TS types is ok because they get optimized out of the bundle.
import { type ArticleData, type ArticleSection } from '@xyla/article';
import { useCalculatorDefinition } from './useCalculatorDefinition';
import { Interpreter } from './Interpreter';
import { calculateScores, interpretScores } from './calculation_utils';

const responsiveSpacing = { xs: 2, md: 3 };

const MoreInputsNeededInterpretationParagraph = () => {
  return (
    <div
      className={'brandable--article-paragraph-title'}
      data-identifier={'interpretation'}
    >
      <Typography>
        Please provide more information to compute the score.
      </Typography>
    </div>
  );
};

interface CalculatorProps {
  setArticleData?: (updateFn: DraftFunction<ArticleData>) => void;
  sectionData: ArticleSection;
  sectionIndex: number;
  children: JSX.Element[];
  paragraphsToSectionBodyElement: (paragraphs: JSX.Element[]) => JSX.Element;
  disabled?: boolean;
}

/** Renders the calculator UI. Reads data from the section_data.metadata field. */
export const Calculator = ({
  setArticleData,
  children,
  sectionData,
  sectionIndex,
  paragraphsToSectionBodyElement,
  disabled,
}: CalculatorProps) => {
  // Parse data given in the article section
  const calculatorData = sectionData.metadata as CalculatorData;
  const { calculatorDefinition, defaultValues } = useCalculatorDefinition(
    calculatorData.calculator_slug
  );

  // pull apart the when to use paragraphs from the interpretation paragraphs
  const whenToUseParagraphs = children.filter(
    (node) => node.props['data-identifier'] === 'when_to_use'
  );

  // Pull apart the formula paragraphs
  const formulaParagraphs = children.filter(
    (node) => node.props['data-identifier'] === 'formula'
  );

  const whenToUseSectionElement =
    whenToUseParagraphs.length > 0
      ? paragraphsToSectionBodyElement(whenToUseParagraphs)
      : null;

  const formulaSectionElement =
    formulaParagraphs.length > 0
      ? paragraphsToSectionBodyElement(formulaParagraphs)
      : null;

  const interpretationParagraphs = children.filter(
    (node) => node.props['data-identifier'] === 'interpretation'
  );

  const interpretationSectionElement =
    interpretationParagraphs.length > 0 ? (
      paragraphsToSectionBodyElement(interpretationParagraphs)
    ) : (
      <MoreInputsNeededInterpretationParagraph />
    );

  /** We use this ref to compare values to the previous render cycle, to avoid update loops */
  const previousValuesRef = useRef<CalculatorValues>(
    calculatorData.parsed_values
  );

  // Handler to update values and calculate the score
  const handleValuesChange = (values: { [key: string]: any }) => {
    if (!calculatorData || !calculatorDefinition) {
      return;
    }

    const valuesWithDefaults = {
      ...defaultValues,
      ...values,
    };

    // Run the actual calculation to get a set of scores
    let calculatedScores = calculateScores(
      calculatorData.calculator_slug,
      valuesWithDefaults
    );

    // Prepare the next ArticleData state
    const newCalculatorData: CalculatorData = {
      ...calculatorData,
      // These are the values updated by the user in the UI (also pre-filled from the query)
      parsed_values: valuesWithDefaults,
      has_output: Boolean(calculatedScores),
    };

    // Interpret the scores
    let orderInSection = children.length;
    const interpretationParagraphs = interpretScores(
      calculatedScores,
      new Interpreter(calculatorDefinition.data_interpretation)
    );
    interpretationParagraphs.forEach((paragraph) => {
      paragraph.order_in_section = orderInSection;
      orderInSection++;
    });

    // Replace the interpretation paragraphs with the new ones
    setArticleData?.((draft) => {
      // Sometimes the draft can go away but this function still is called.
      if (sectionIndex >= draft.articlesection_set.length) {
        return;
      }

      // KEEP IN SYNC WITH xyla/django/components/components/component_helpers/update_history.py
      // The python function is needed because the app handles history differently than the web.
      // The app passes the updated history metadata to the FE and performs the below update on the BE.
      // The strategy of updating on the FE is probably better, but the app doesn't allow this.

      draft.articlesection_set[sectionIndex].metadata = newCalculatorData;

      // Update the changed interpretation paragraphs
      const newArticleParagraphs = [
        ...draft.articlesection_set[sectionIndex].articleparagraph_set.filter(
          (p) => p.identifier !== 'interpretation'
        ),
        ...interpretationParagraphs,
      ];

      draft.articlesection_set[sectionIndex].articleparagraph_set =
        newArticleParagraphs;

      // Now that the calculator section is dirty, invalidate any sections following it
      for (let i = sectionIndex + 1; i < draft.articlesection_set.length; i++) {
        draft.articlesection_set[i].metadata = {
          ...draft.articlesection_set[i].metadata,
          invalidated: true,
        };
      }
    });
  };

  // Run our change handler to re-calculate the state when:
  // - parent updates calculator data
  useEffect(() => {
    // Only update if data has actually changed from the previous state
    if (
      JSON.stringify(calculatorData.parsed_values) !==
      JSON.stringify(previousValuesRef.current)
    ) {
      // Update the "previous" reference
      previousValuesRef.current = calculatorData.parsed_values;
      handleValuesChange(calculatorData.parsed_values);
    }
  }, [calculatorData.parsed_values]);

  // If there's no data in the article, show nothing
  if (!calculatorData) {
    return null;
  }

  // Show spinner while we're fetching the calculator definition from the server
  const calculatorContent = calculatorDefinition ? (
    <>
      <Grid container spacing={responsiveSpacing}>
        {/* Calculator card */}
        <Grid
          size={{
            xs: 12,
            md: 7,
          }}
        >
          <OmniCalculator
            calculatorDefinition={calculatorDefinition}
            calculatorData={calculatorData}
            onValuesChange={handleValuesChange}
            whenToUseSectionElement={whenToUseSectionElement}
            formulaSectionElement={formulaSectionElement}
            disabled={disabled}
          />
        </Grid>
        {/* Interpretation Section */}
        <Grid
          size={{
            xs: 12,
            md: 5,
          }}
        >
          <Box
            // TODO(andy): Should read OE's global header height instead
            sx={{ position: 'sticky', top: '81px' }}
          >
            <Card
              variant='outlined'
              sx={{
                p: 2,
                borderRadius: 2,
                // Override default article styles to match CalculatorHeading.
                // We have to use the passed ReactNode because it gets pre-rendered in deriveGeneratedArticle
                '.brandable--article-paragraph-title > div:first-of-type': {
                  lineHeight: '1.5 !important',
                  fontSize: '1.125rem !important',
                  fontWeight: '600 !important',
                  mt: '0 !important',
                  mb: '0.5em !important',
                },
              }}
            >
              {interpretationSectionElement}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    // TODO(andy): This might not work nicely when the answer is typing out
    <Box sx={{ textAlign: 'center', my: 2 }}>
      <CircularProgress size={20} />
    </Box>
  );

  return (
    <Box
      sx={{
        // Force this container to be full screen width, even though our parent has fixed width.
        // Slightly less than 100vw to prevent horizontal overflow.
        width: '96vw',
        mx: 'calc(50% - 48vw)',
        py: 2,
      }}
    >
      <Container>{calculatorContent}</Container>
    </Box>
  );
};
