import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  Typography,
} from '@mui/material';
import DOMPurify from 'isomorphic-dompurify';
import { split, TxtParentNodeWithSentenceNodeContent } from 'sentence-splitter';
import { titleCase } from 'title-case';
import { SnippetDisplayData, SnippetImageDetail } from '../types';
import { parseHtmlAndWhiteSpace } from '../utils';
import parse from 'html-react-parser';
import { usePapaParse } from 'react-papaparse';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  gridClasses,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

/*
Strategy:
* Split the source text into paragraphs (i.e. if it's an abstract with multiple paragraphs).
* For each paragraph:
*   Split the source text into sentences.
*   Determine which sentences are critical, defined as those that contain a critical sentence.
*   Group continguous critical sentences into a single group.
*   For each group, use the sentence before and after the group as context.
*   Highlight the critical sentences in the group.
*/

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function splitAbstractIntoParagraphs(abstract: string): string[] {
  // Pagraph splits are where there is "<ALL CAPS>: ". We split this by regex
  const paragraphTitleRegex = /([A-Z\s/]+: )/g;
  const paragraphSplits = abstract.split(paragraphTitleRegex);

  // Progress through the paragraph splits, combining the text between the splits
  // if it is not a paragraph split
  const paragraphs: string[] = [];
  let currentParagraph = '';
  for (const paragraphSplit of paragraphSplits) {
    if (paragraphSplit.match(paragraphTitleRegex)) {
      // If this is a paragraph split, push the current paragraph and start a new one
      if (currentParagraph !== '') {
        paragraphs.push(currentParagraph);
      }
      currentParagraph = toTitleCase(paragraphSplit);
    } else {
      // If this is not a paragraph split, add it to the current paragraph
      currentParagraph += paragraphSplit;
    }
  }

  // Push the last paragraph
  if (currentParagraph !== '') {
    paragraphs.push(currentParagraph);
  }
  return paragraphs.map((paragraph) => paragraph.trim());
}

interface Sentence {
  text: string;
  isCritical?: boolean;
}

function sentenceObjectsToSentences(
  sentenceObjects: TxtParentNodeWithSentenceNodeContent[]
): Sentence[] {
  // Sentences includes things labeled sentences and things not labeled sentences (e.g. whitespace). We want to group all the non-sentences into a single sentence.

  const sentences: Sentence[] = [];
  let currentSentence: Sentence = { text: '' };
  for (const sentenceObject of sentenceObjects) {
    currentSentence.text += sentenceObject.raw;
    if (sentenceObject.type === 'Sentence') {
      if (currentSentence.text) {
        sentences.push(currentSentence);
      }
      currentSentence = { text: '' };
    }
  }
  if (currentSentence.text) {
    sentences.push(currentSentence);
  }
  return sentences;
}

function groupContiguousCriticalSentences(sentences: Sentence[]): Sentence[] {
  // Group contiguous critical sentences into a single sentence

  const groupedSentences: Sentence[] = [];
  let currentCriticalGroup: Sentence[] = [];

  function pushCurrentCriticalGroup() {
    if (currentCriticalGroup.length > 0) {
      groupedSentences.push({
        text: currentCriticalGroup.map((sentence) => sentence.text).join(' '),
        isCritical: true,
      });
      currentCriticalGroup = [];
    }
  }

  for (const sentence of sentences) {
    if (sentence.isCritical) {
      currentCriticalGroup.push(sentence);
    } else {
      if (currentCriticalGroup.length > 0) {
        pushCurrentCriticalGroup();
      }
      groupedSentences.push({
        text: sentence.text,
        isCritical: false,
      });
    }
  }

  // Push the last group
  if (currentCriticalGroup.length > 0) {
    pushCurrentCriticalGroup();
  }

  return groupedSentences;
}

interface Highlight {
  isMoreTextBefore: boolean;
  textBefore: string;
  highlightedText: string;
  textAfter: string;
  isMoreTextAfter: boolean;
}

function sentencesWithoutContiguousCriticalityToHighlights(
  sentencesWithoutContiguousCriticality: Sentence[],
  moreParagraphsBefore: boolean,
  moreParagraphsAfter: boolean
): Highlight[] {
  const highlights: Highlight[] = [];
  for (let i = 0; i < sentencesWithoutContiguousCriticality.length; i++) {
    const sentence = sentencesWithoutContiguousCriticality[i];
    if (!sentence.isCritical) {
      continue;
    }

    // We've found a critical sentence
    const textBefore =
      i > 0 ? sentencesWithoutContiguousCriticality[i - 1].text : '';
    const textAfter =
      i < sentencesWithoutContiguousCriticality.length - 1
        ? sentencesWithoutContiguousCriticality[i + 1].text
        : '';
    highlights.push({
      isMoreTextBefore: i > 1 || moreParagraphsBefore,
      textBefore,
      highlightedText: sentence.text,
      textAfter,
      isMoreTextAfter:
        i < sentencesWithoutContiguousCriticality.length - 2 ||
        moreParagraphsAfter,
    });
  }
  return highlights;
}

function paragraphToHighlights(
  paragraph: string,
  criticalSentences: string[],
  moreParagraphsBefore: boolean,
  moreParagraphsAfter: boolean
): Highlight[] {
  // Split the paragraph into sentences
  const sentenceObjects = split(paragraph);

  // Sentences includes things labeled sentences and things not labeled sentences (e.g. whitespace). We want to group all the non-sentences
  // into the next sentence.
  const sentences = sentenceObjectsToSentences(sentenceObjects);

  // Assign criticality to each sentence
  const sentencesWithCriticality = sentences.map((sentence) => {
    return {
      ...sentence,
      isCritical: criticalSentences.some((criticalSentence) =>
        sentence.text.includes(criticalSentence)
      ),
    };
  });

  // Group contiguous critical sentences into a single sentence
  const sentencesWithoutContiguousCriticality: Sentence[] =
    groupContiguousCriticalSentences(sentencesWithCriticality);

  // Derive the highlights from the sentences
  const highlights = sentencesWithoutContiguousCriticalityToHighlights(
    sentencesWithoutContiguousCriticality,
    moreParagraphsBefore,
    moreParagraphsAfter
  );
  return highlights;
}

function parseSectionName(header: string): string | null {
  // get all key values pairs from the header formatted as "key: value"
  const keyValuePairs = header
    .split('\n')
    .map((line) => line.split(': '))
    .filter((line) => line.length === 2);
  const headerData: Record<string, string> = keyValuePairs.reduce(
    (acc: Record<string, string>, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {}
  );

  // return the value of the first key found
  const keyOptions = [
    'Subsection',
    'Section Title',
    'Section',
    'label section',
  ];

  for (const key of keyOptions) {
    if (key in headerData) {
      return titleCase(headerData[key]);
    }
  }
  return null;
}

function SnippetTextDisplayAroundHighlightsOnly({
  highlights,
}: {
  highlights: Highlight[];
}): JSX.Element {
  return (
    <Box>
      {highlights.map((highlight, index) => (
        <Box
          key={index}
          mt={index === 0 ? 1 : 2}
          mb={index === highlights.length - 1 ? 1 : 2}
        >
          <Box>
            {highlight.isMoreTextBefore && '...'}
            {highlight.textBefore}
            <mark>{highlight.highlightedText}</mark>
            {highlight.textAfter}
            {highlight.isMoreTextAfter && '...'}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function SnippetTextDisplayFullText({
  sectionName,
  paragraphs,
}: {
  sectionName: string | null;
  paragraphs: string[];
}): JSX.Element {
  // Split text paragraphs into their own <p> tags and replace newlines with <br>
  const combinedString = paragraphs
    .flatMap((p) => p.split('\n\n'))
    .map((p) => `<p>${p.replace(/\n/g, '<br />')}</p>`)
    .join('');
  const sanitizedString = DOMPurify.sanitize(combinedString, {
    FORBID_TAGS: ['a'],
  });
  const manuallyCleanedString = parseHtmlAndWhiteSpace(sanitizedString);
  const parsedHTML = parse(manuallyCleanedString);

  return (
    <Box my={1}>
      {sectionName && (
        <Box fontWeight='bold' mb={0.4}>
          {sectionName}
        </Box>
      )}
      <Box>{parsedHTML}</Box>
    </Box>
  );
}

function SnippetCSVTableDisplay({
  csvString,
  headerString,
}: {
  csvString: string;
  headerString: string;
}): JSX.Element {
  const { readString } = usePapaParse();

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);

  // Regular expression to match each key-value pair in the snippet metadata text
  const snippetMetadataRegex =
    /^([\w\s]+):\s((?:.+?(?:\n(?![\w\s]+:).+?)*)?)/gm;
  const matches = Array.from(headerString.matchAll(snippetMetadataRegex));
  const tableMetadata: Record<string, string> = {};
  for (let match of matches) {
    tableMetadata[match[1].trim()] = match[2].trim();
  }

  useEffect(() => {
    readString(csvString, {
      header: true, // Consider the first row as headers
      skipEmptyLines: true, // Skip empty lines
      complete: (result: any) => {
        const parsedData = result.data;

        // Generate columns from CSV headers
        const columns: GridColDef[] = parsedData[0]
          ? Object.keys(parsedData[0]).map((key) => ({
              field: key,
              headerName: key.replace(/Unnamed: \d+/, ''), // Remove pandas default header names
              flex: 1,
              sortable: false,
            }))
          : [];

        // Generate rows with unique ID
        const rows: GridRowsProp = parsedData.map(
          (row: any, index: number) => ({
            id: index, // Adding a unique ID to each row
            ...row,
          })
        );

        setColumns(columns);
        setRows(rows);
      },
      error: (error: any) => {
        console.error('Error parsing CSV:', error);
      },
    });
  }, [csvString, readString]);

  // fallback to displaying regular snippet text if parsing fails
  if (columns.length === 0 || rows.length === 0) {
    return (
      <SnippetTextDisplayFullText sectionName={null} paragraphs={[csvString]} />
    );
  }

  return (
    <Box
      sx={{
        height: 400,
        width: '100%',
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
      }}
    >
      <Typography fontWeight={'bold'} color='textSecondary'>
        {tableMetadata['Table Caption']}
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        autoHeight
        getRowHeight={() => 'auto'}
        disableColumnMenu
        hideFooter={true}
        initialState={{ density: 'compact' }}
        sx={{
          [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
            outline: 'transparent',
          },
          [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
        }}
      />
      <Typography variant='caption' color='textSecondary'>
        {tableMetadata['Table Footer']}
      </Typography>
    </Box>
  );
}

function SnippetImageDisplay({
  text,
  image_data,
  index,
}: SnippetDisplayData & {
  image_data: SnippetImageDetail;
  index: number;
}): JSX.Element {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Card
        key={index}
        sx={{
          mt: 1,
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={() => setOpenModal(true)}
      >
        <CardMedia
          component='img'
          alt={text}
          image={
            image_data.image_b64
              ? `data:image/jpeg;base64,${image_data.image_b64}`
              : image_data.image_url
          }
        />
      </Card>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby='image-dialog-title'
        aria-describedby='image-dialog-description'
        fullWidth
        maxWidth='lg'
      >
        {/* TODO: use citation or link to source */}
        <DialogTitle id='image-dialog-title'>Image View</DialogTitle>
        <DialogContent>
          <img
            src={
              image_data.image_b64
                ? `data:image/jpeg;base64,${image_data.image_b64}`
                : image_data.image_url
            }
            alt={text}
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SnippetTextDisplay({
  text,
  critical_sentences,
}: SnippetDisplayData): JSX.Element {
  const snippetParts = text.split('----------');
  const headerString = snippetParts[0].trim();
  const bodyString = snippetParts.slice(-1)[0].trim();

  const sectionName = parseSectionName(headerString);

  const paragraphs = splitAbstractIntoParagraphs(bodyString);

  let highlights: Highlight[] = [];
  if (critical_sentences && critical_sentences.length > 0) {
    highlights = paragraphs
      .map((paragraph, i) =>
        paragraphToHighlights(
          paragraph,
          critical_sentences,
          i > 0,
          i < paragraphs.length - 1
        )
      )
      .flat();
  }

  if (headerString.includes('table_format: csv')) {
    return (
      <SnippetCSVTableDisplay
        csvString={bodyString}
        headerString={headerString}
      />
    );
  }

  if (highlights.length > 0) {
    return <SnippetTextDisplayAroundHighlightsOnly highlights={highlights} />;
  } else {
    return (
      <SnippetTextDisplayFullText
        sectionName={sectionName}
        paragraphs={paragraphs}
      />
    );
  }
}

function pctOverlap(set1: Set<string>, set2: Set<string>): number {
  // Get the percent overlap between the intersection of two sets and the union of the two sets
  const intersection = new Set([...set1].filter((x) => set2.has(x)));
  const union = new Set([...set1, ...set2]);
  return intersection.size / union.size;
}

const MAXIMUM_SNIPPET_OVERLAP = 0.95;

export function SnippetDataDisplay({
  snippetDisplayDataArray,
  open,
}: {
  snippetDisplayDataArray: SnippetDisplayData[];
  open: boolean;
}): JSX.Element {
  // First we need to dedupe the snippets. Snippets can be nearly identical, but with the drug name swapped between the generic and brand name.
  // We want to dedupe these snippets so that we don't show the same snippet twice, even though the text is not identical.
  // Define duplicate as contained 95% of the same bag of words as another snippet.
  const allSnippetBagOfWords = snippetDisplayDataArray.map(
    (snippetDisplayData) => new Set(snippetDisplayData.text.split(' '))
  );

  const dedupedSnippetDisplayDataArray: SnippetDisplayData[] = [];
  const dedupedSnippetBagOfWords: Set<string>[] = [];
  for (let i = 0; i < snippetDisplayDataArray.length; i++) {
    // Get the current snippet bag of words
    const snippetDisplayData = snippetDisplayDataArray[i];
    const snippetBagOfWords = allSnippetBagOfWords[i];

    // Check if the current snippet is a duplicate of any of the previous snippets
    let isDuplicate = false;
    for (const comparatorBagOfWords of dedupedSnippetBagOfWords) {
      const pctOverlapValue = pctOverlap(
        snippetBagOfWords,
        comparatorBagOfWords
      );
      if (pctOverlapValue > MAXIMUM_SNIPPET_OVERLAP) {
        isDuplicate = true;
        break;
      }
    }

    // If the current snippet is not a duplicate, add it to the deduped list
    if (!isDuplicate) {
      dedupedSnippetDisplayDataArray.push(snippetDisplayData);
      dedupedSnippetBagOfWords.push(snippetBagOfWords);
    }
  }

  return (
    <Collapse in={open}>
      {dedupedSnippetDisplayDataArray.map((snippetData, index) =>
        snippetData.image_data ? (
          <SnippetImageDisplay
            key={index}
            {...snippetData}
            image_data={snippetData.image_data}
            index={index}
          />
        ) : (
          <SnippetTextDisplay key={index} {...snippetData} />
        )
      )}
    </Collapse>
  );
}
