import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { CalloutCitedDocument } from './types';
import { getCalloutDocumentAttributionElements } from '@xyla/openevidence-shared-functionality';

export interface DocumentDetailsProps extends CalloutCitedDocument {
  sx?: SxProps<Theme>;
}

export const DocumentDetails = ({
  title,
  authors,
  publication_info,
  published_date_text,
  href,
  sx = {},
}: DocumentDetailsProps) => {
  const { titleForCitation, extendedCitation } =
    getCalloutDocumentAttributionElements({
      title,
      authors,
      publication_info,
    });

  return (
    <Box
      sx={[
        {
          dispay: 'flex',
          flexDirection: 'column',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography sx={{ fontSize: 'inherit' }}>
        {href ? (
          <Link
            href={href}
            target='_blank'
            sx={{
              color: 'primary.main',
              fontWeight: 450,
            }}
          >
            {titleForCitation}
          </Link>
        ) : (
          titleForCitation
        )}
      </Typography>
      {extendedCitation && (
        <Typography sx={{ fontSize: 'inherit' }}>{extendedCitation}</Typography>
      )}
      {published_date_text && (
        <Typography
          sx={{
            fontSize: 'inherit',
            fontStyle: 'italic',
            color: 'grey.700',
          }}
        >
          {published_date_text}
        </Typography>
      )}
    </Box>
  );
};
