import { createTheme } from '@mui/material/styles';

import COLORS from './color_definitions';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.MAIN_SIGNAL,
    },
    // @ts-ignore
    infoBox: {
      main: '#edede3',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0A0A0A',
          color: '#FFFFFF',
          '& .MuiMenuItem-root': {
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#363636',
            },
          },
          '& .MuiListItemIcon-root, & .MuiSvgIcon-root': {
            color: '#FFFFFF',
          },
        },
      },
    },
    // @ts-ignore - DataGrid is defined in MUI X but it uses the same theme
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // https://github.com/mui/mui-x/issues/14898
          '& *': {
            scrollBehavior: 'auto',
          },
          '.MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Schibsted Grotesk',
    // @ts-ignore
    serif: {
      fontFamily: 'Lora',
    },
    h1: {
      fontSize: '4.25rem',
      fontWeight: 500,
      letterSpacing: '-0.022em',
      textAlign: 'center',
      margin: '0.625em 0',
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
