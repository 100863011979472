import slugifyPkg from 'slugify';
import { TranslateFunction } from './TranslationProvider';

export function slugify(s: string, options: any = undefined): string {
  options = options ?? {};
  return slugifyPkg(s, {
    lower: true,
    strict: true,
    trim: true,
    ...options,
  });
}

export function truncate(s: string, maxLength: number = 20): string {
  if (s.length <= maxLength) {
    return s;
  } else {
    return s.slice(0, maxLength - 3) + '...';
  }
}

export const conjoinStrings = (strings: string[]) => {
  if (strings.length <= 1) {
    return strings.join('');
  } else if (strings.length === 2) {
    return strings.join(' and ');
  } else {
    return strings.slice(0, -1).join(', ') + ', and ' + strings.slice(-1);
  }
};

/**
 * Split english text into sentences.
 *
 * Adapted from https://stackoverflow.com/a/31430385
 */
export function splitSentences(s: string): string[] {
  return s
    .replace(/(\.+|:|!|\?)("*|'*|\)*|}*|]*)(\s|\n|\r|\r\n)/gm, '$1$2|||')
    .split('|||');
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/**
 * Formats a date to a user-friendly string relative to now
 * @param date - the date to compare to now
 * @param absoluteFormatFn - if the date is >= 7 days, this function will be
 * used to format the absolute date
 * @param t - the translation function to localize text
 */
export function getRelativeDate(
  date: Date,
  absoluteFormatFn: (_date: Date) => string,
  t: TranslateFunction
): string {
  const now = new Date();
  // Get difference in seconds
  const delta = Math.floor((now.getTime() - date.getTime()) / 1000);
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  if (delta < minute) {
    return t('Just now');
  } else if (delta < hour) {
    const diff = Math.floor(delta / minute);
    if (diff === 1) {
      return t('1 minute ago');
    } else {
      return t(`{{N}} minutes ago`, { N: diff });
    }
  } else if (delta < day) {
    const diff = Math.floor(delta / hour);
    if (diff === 1) {
      return t('1 hour ago');
    } else {
      return t(`{{N}} hours ago`, { N: diff });
    }
  } else if (delta < week) {
    const diff = Math.floor(delta / day);
    return diff < 2 ? t('Yesterday') : t(`{{N}} days ago`, { N: diff });
  } else {
    return absoluteFormatFn(date);
  }
}

/**
 * Get x as a single item
 */
export function asItem<T>(x: T | T[]): T {
  return Array.isArray(x) ? x[0] : x;
}

/** Returns a random item from the given array, using the given weights. */
export function getRandomElement<T>(
  array: T[],
  weights?: number[]
): T | undefined {
  // If weights are not given, randomly sample.
  if (!weights) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  // Validate that weights array matches items array length
  if (weights.length !== array.length) {
    throw new Error('Weights array must have the same length as items array');
  }

  // Calculate the total sum of weights
  const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);

  // If total weight is greater than 1, throw an error
  if (totalWeight > 1) {
    throw new Error('Total weight cannot exceed 1');
  }

  // Generate a random number between 0 and 1
  let randomWeight = Math.random();

  // If the random number falls within the undefined range, return undefined
  // This is useful in case we only have one asset, but only want to display it
  // in x% of cases.
  if (randomWeight > totalWeight) {
    return undefined;
  }

  // Iterate through the items and subtract their weight from the randomWeight
  for (let i = 0; i < array.length; i++) {
    randomWeight -= weights[i];
    if (randomWeight <= 0) {
      return array[i];
    }
  }

  // Fallback in case of rounding errors
  return array[array.length - 1];
}

// Note(oliver) - useTimerWorkers is purposefully not exported from the main entry point because it uses import.meta which is not supported by React-Native
// export { useTimerWorkers } from './useTimerWorkers';
export { Queue } from './Queue/Queue';
export {
  LocalizedText,
  TranslationProvider,
  useTranslation,
} from './TranslationProvider';
export type { TranslateFunction } from './TranslationProvider';
export { useFixedElementPadding } from './useFixedElementPadding';
export {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
  useLocalStorage,
} from './useLocalStorage';
export * from './whyCited';
export * from './countries';
