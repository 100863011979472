// Registration type and access level are similar but separate concepts.
// Registration type describes a parameter to the INPUT of the registration process.
// Access level describes the OUTPUT of the registration process.

// Registration type is determined by the way the user registered. Currently the supported mechanisms are:
// - HCPs (including NPIs), through /onboarding
// - Students, through /onboarding
// - Patients and caregivers, through /onboarding-patients (disabled as of 2024-12-19)
// NOTE: This has been moved to the RegistrationTypeEnum enum as of 2025-02-18.

// Access role is an output of the registration process. Depending on the details of the registration, the server
// will assign the user a certain auth0 role. The role determines rate limits and permissions.
export const NPI_ACCESS_ROLE_NAME = 'npi';
export const VIP_ACCESS_ROLE_NAME = 'vip';
export const NONNPI_HCP_ACCESS_ROLE_NAME = 'nonnpi_hcp';
export const PATIENT_ACCESS_ROLE_NAME = 'patient';
export const STUDENT_ACCESS_ROLE_NAME = 'student';
export const PRIVATE_ACCESS_ROLE_NAME = 'private';
export type OEAccessRoleName =
  | typeof NPI_ACCESS_ROLE_NAME
  | typeof VIP_ACCESS_ROLE_NAME
  | typeof NONNPI_HCP_ACCESS_ROLE_NAME
  | typeof PATIENT_ACCESS_ROLE_NAME
  | typeof STUDENT_ACCESS_ROLE_NAME
  | typeof PRIVATE_ACCESS_ROLE_NAME;

// Currently, Auth0 roles map 1:1 to Auth0 permissions. We only assign roles to users,
// so we need to define the role ids here. For the app, roles are assigned by OE API.
// (@see src/piapiac/xyla/django/openevidence_app/views.py::OE_ROLE_NAME_TO_ID)

// Used for validated US HCPs with NPIs
const ASKOE_ROLE_USNPI_ACCESS = process.env.ASKOE_ROLE_USNPI_ACCESS ?? '';

// Used for VIPs
const ASKOE_ROLE_VIP_ACCESS = process.env.ASKOE_ROLE_VIP_ACCESS ?? '';

// Used for US HCPs without NPIs and non-US HCPs
const ASKOE_ROLE_NONNPI_HCP_ACCESS =
  process.env.ASKOE_ROLE_NONNPI_HCP_ACCESS ?? '';

// Used for patients and caregivers
const ASKOE_ROLE_PATIENT_ACCESS = process.env.ASKOE_ROLE_PATIENT_ACCESS ?? '';

// Used for students
const ASKOE_ROLE_STUDENT_ACCESS = process.env.ASKOE_ROLE_STUDENT_ACCESS ?? '';

// Used for VIPs with access to private.openevidence.com
const ASKOE_ROLE_PRIVATE_ACCESS = process.env.ASKOE_ROLE_PRIVATE_ACCESS ?? '';

// Auth0 permissions. These are the actual strings that are stored in the token and sent to the backend
export const ASKOE_PERMISSION_USNPI_ACCESS = 'write:componentbasedarticles';
export const ASKOE_PERMISSION_NONNPI_HCP_ACCESS = 'nonnpi_hcp_oe_access';
export const ASKOE_PERMISSION_PATIENT_ACCESS = 'patient_oe_access';
export const ASKOE_PERMISSION_STUDENT_ACCESS = 'student_oe_access';
export const ASKOE_PERMISSION_PRIVATE_ACCESS = 'private_oe_access';

// You should only use the human readable type above and these mappings in the codebase.
export const OE_ROLE_NAME_TO_ID: Record<OEAccessRoleName, string> = {
  [NPI_ACCESS_ROLE_NAME]: ASKOE_ROLE_USNPI_ACCESS,
  [VIP_ACCESS_ROLE_NAME]: ASKOE_ROLE_VIP_ACCESS,
  [NONNPI_HCP_ACCESS_ROLE_NAME]: ASKOE_ROLE_NONNPI_HCP_ACCESS,
  [PATIENT_ACCESS_ROLE_NAME]: ASKOE_ROLE_PATIENT_ACCESS,
  [STUDENT_ACCESS_ROLE_NAME]: ASKOE_ROLE_STUDENT_ACCESS,
  [PRIVATE_ACCESS_ROLE_NAME]: ASKOE_ROLE_PRIVATE_ACCESS,
};

export const PERMISSION_TO_ROLE_NAME_MAP: Record<string, OEAccessRoleName> = {
  [ASKOE_PERMISSION_STUDENT_ACCESS]: STUDENT_ACCESS_ROLE_NAME,
  [ASKOE_PERMISSION_PRIVATE_ACCESS]: PRIVATE_ACCESS_ROLE_NAME,
  // NB(andy): VIPs are functionally the same as NPIs. This property should be
  // updated if we need `auth0AccessLevel` in Mixpanel tracking. (constructFullEventsPropsServerSide.ts)
  // Ideally we should clean up unused Mixpanel props.
  [ASKOE_PERMISSION_USNPI_ACCESS]: NPI_ACCESS_ROLE_NAME,
  [ASKOE_PERMISSION_NONNPI_HCP_ACCESS]: NONNPI_HCP_ACCESS_ROLE_NAME,
  [ASKOE_PERMISSION_PATIENT_ACCESS]: PATIENT_ACCESS_ROLE_NAME,
};

// Because users may have multiple roles, and roles may have shared permissions, we use this list as an ordering
// to determine what the proper access role is based on the roles they've been assigned.
export const ACCESS_ROLE_PRECEDENCE: OEAccessRoleName[] = [
  PRIVATE_ACCESS_ROLE_NAME,
  // For backwards compatibility, student access needs to have precedence over the non PRIVATE_ACCESS_ROLE_NAME roles
  STUDENT_ACCESS_ROLE_NAME,
];

export interface RawFile {
  name: string;
  base64: string;
}

// Signifies an error returned by the API where we can display the message to the user.
export const USER_INPUT_ERROR = 'user_input_error';

export * from './article/types';
