/** converts a citation to a list of numbers, e.g. "[1,2,5-7]" -> [1,2,5,6,7] */
export function expandStringToList(str: string): number[] {
  str = str.replace(/\s/g, '').slice(1, -1); // remove spaces and brackets

  const ranges = str.split(',');
  const expandedNumbers = [];

  for (let range of ranges) {
    if (range.includes('-')) {
      const [start, end] = range.split('-').map(Number);
      for (let i = start; i <= end; i++) {
        expandedNumbers.push(i);
      }
    } else {
      expandedNumbers.push(Number(range));
    }
  }

  return expandedNumbers;
}

/**
 * Code for splitting text based on where the brackets are. Designed so that citations end up in their own array elements,
 * so that we can keep track of which parts of the string are citations and which or not.
 *
 * Opening brackets are always at the start of their array element, and closing brackets are at the end.
 *
 * Example:
 * splitByBracket("This is a [citation] and [another citation]") -> ["This is a ", "[citation]", " and ", "[another citation]"]
 * splitByBracket("Here is a [comment with [citation]]") -> ["Here is a ", "[comment with ", "[citation]", "]"]
 * @param text Text to split by bracket
 * @returns List of strings, split by where the brackets are
 */
export function splitByBracket(text: string): string[] {
  const chars = [...text];
  const splitTerms = [''];
  chars.forEach((char) => {
    if (char === '[') {
      splitTerms.push('[');
    } else if (char === ']') {
      splitTerms[splitTerms.length - 1] += ']';
      splitTerms.push('');
    } else {
      splitTerms[splitTerms.length - 1] += char;
    }
  });
  return splitTerms.filter((term) => term !== '');
}
