import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ActionButton, ActionButtonProps } from './ActionButton';

export interface HeaderProps {
  icon?: React.ReactNode;
  title: string;
  actions?: ActionButtonProps[];
  sx?: SxProps<Theme>;
  trailingHeaderElement?: React.ReactNode;
}

export const Header = ({
  icon,
  title,
  actions = [],
  sx = {},
  trailingHeaderElement,
}: HeaderProps) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'row',
          gap: 1,
          px: 2,
          py: 1.5,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon && <Box sx={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>}
      <Typography lineHeight={1.35}>{title}</Typography>
      {actions && actions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        >
          {actions.map((actionButtonProps, index) => (
            <ActionButton key={index} {...actionButtonProps} />
          ))}
        </Box>
      )}
      {trailingHeaderElement && <Box ml='auto'>{trailingHeaderElement}</Box>}
    </Box>
  );
};
