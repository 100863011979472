'use client';

import { useCallback, useState } from 'react';

// If cookies are disabled, attempting to access localStorage will throw an error.
// To avoid this, we wrap the localStorage access in a try-catch block.
export function getLocalStorageItem(key: string): string | null {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return window.localStorage.getItem(key);
  } catch (error) {
    return null;
  }
}

export function setLocalStorageItem(key: string, value: string): boolean {
  try {
    // eslint-disable-next-line no-restricted-syntax
    window.localStorage.setItem(key, value);
    return true;
  } catch (error) {
    return false;
  }
}

export function removeLocalStorageItem(key: string): boolean {
  try {
    // eslint-disable-next-line no-restricted-syntax
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

type UseLocalStorageReturn<T> = [T, (value: T) => void];

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): UseLocalStorageReturn<T> {
  const getInitialValue = useCallback(() => {
    try {
      const item = getLocalStorageItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(getInitialValue);

  const setValue = useCallback(
    (value: T) => {
      try {
        setLocalStorageItem(key, JSON.stringify(value));
        setStoredValue(value);
      } catch (error) {}
    },
    [key]
  );

  return [storedValue, setValue];
}
