/*
    The data we get from the partial output as the article is generated is quite "chunky". We
    get data every ~2s or so, and it may be the case that we don't get any new data for a while
    and then all of a sudden we get an entire new section. Or it might be the case that we get
    a few new words each request. In principle if we're always going to use streaming we could
    request fast enough that we get only a word or two each time, but back of the envelope
    we would need to do ~5 requests per second to get that. That seems like a lot, and also we'll
    almost certainly have cases where we getting big chunks anyways.

    This hook will act as a buffer for data as it comes in, managing a typewriter output based on this
    data that will output a similarly formatted output, just with a regular stream of output data.

    If the "data" were raw text we could use exactly the typewriter hook and maybe one state. But
    the data in this case is actually `ArticleData` which is a complex object with many nested
    objects. So we need to do some extra work in this hook to manage this.
*/
import { isEqual } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { Timers, TimerStrategy, useTypewriter } from '.';
import { ArticleData } from '../article/types';
import {
  ResetData,
  ResetType,
  UseTypewriterTimings,
  TableComponent,
  TableWord,
} from './types';
import { SplitType } from './useTextTypewriter';

function splitWords(text: string, splitChar: string): string[] {
  return text.split(splitChar);
}

/**
 * Splits up a table, which is represented by a list of rows into a list of table-words, which represent a word in a given cell.
 * @param table_data
 * @param splitChar
 * @returns List of table-words representing every word appearing in the table going row by row from left to right.
 */
function splitTable(
  tableComponent: TableComponent,
  splitChar: string
): TableWord[] {
  const splitTableData: TableWord[] = [];
  const table_data = tableComponent.table_data;
  table_data.forEach((row, index) => {
    Object.keys(row).forEach((key) => {
      splitWords(row[key], splitChar).forEach((word) =>
        splitTableData.push({ column_name: key, word: word, row_index: index })
      );
    });
  });
  return splitTableData;
}

/**
 * Combines table-words together to get a partial table based on what the typewriter has processed
 * @param split_table_data
 * @param splitChar
 * @returns
 */
function joinTable(
  split_table_data: TableWord[],
  splitChar: string
): TableComponent {
  const joinedTableData: Record<string, any>[] = [];
  split_table_data.forEach((table_word) => {
    if (!joinedTableData[table_word.row_index]) {
      joinedTableData[table_word.row_index] = {};
    }
    if (!joinedTableData[table_word.row_index][table_word.column_name]) {
      joinedTableData[table_word.row_index][table_word.column_name] =
        table_word.word;
    } else {
      joinedTableData[table_word.row_index][table_word.column_name] +=
        splitChar + table_word.word;
    }
  });
  return { table_data: joinedTableData };
}

const TYPEWRITER_SKIP_SECTION_IDS = ['calculator', 'calculator-unsupported'];

function articleDataToFlatPaths(articleData: ArticleData): string[] {
  // An arbitrarily-deep nested array of strings
  const deepPaths: any = [];
  articleData.articlesection_set.forEach((section, i) => {
    if (TYPEWRITER_SKIP_SECTION_IDS.includes(section.identifier)) {
      return;
    }
    const sectionPrefix = `articlesection_set.${i}`;
    deepPaths.push([
      `${sectionPrefix}.section_title`,
      section.articleparagraph_set.map((paragraph, j) => {
        const paragraphPrefix = `${sectionPrefix}.articleparagraph_set.${j}`;
        return [
          `${paragraphPrefix}.paragraph_title`,
          paragraph.articlespan_set.map((span, k) => {
            const spanPrefix = `${paragraphPrefix}.articlespan_set.${k}`;
            return [`${spanPrefix}.text`, `${spanPrefix}.citations`];
          }),
        ];
      }),
    ]);
  });
  const flatPaths = deepPaths.flat(Infinity);
  return flatPaths as string[];
}

function pathIsCitation(pathParts: string[]): boolean {
  return pathParts[pathParts.length - 1].endsWith('citations');
}

const TABLE_COMPONENT_PREFIX = 'REACTCOMPONENT!:!Table!:!';

/**
 * The typewriter expects table data to be in the following form:
 * [TABLE_COMPONENT_PREFIX, column_names, ...table-words describing the words in each cell of the table]
 * @param tableComponent
 * @param splitChar
 * @returns
 */
function convertTableToTypewriterFormat(
  tableComponent: TableComponent,
  splitChar: string
): [string, string[], ...TableWord[]] {
  let column_names: string[] = [];
  if (tableComponent.table_data.length > 0) {
    column_names = Object.keys(tableComponent.table_data[0]);
  }
  return [
    TABLE_COMPONENT_PREFIX,
    column_names,
    ...splitTable(tableComponent, splitChar),
  ];
}

/**
 * Converts the typewriter output to a string representing the table that can be used in the article data.
 * The input is expected to be in the same format as described in convertTableToTypewriterFormat
 * @param typewriter_data
 * @returns string representing the given table
 */
function convertTypewriterFormatToTableString(
  typewriter_data: [string, string[], ...TableWord[]],
  splitChar: string
): string {
  if (typewriter_data.length === 0) {
    return '';
  }
  if (typewriter_data[0] !== TABLE_COMPONENT_PREFIX) {
    return '';
  }
  if (typewriter_data.length < 3) {
    return '';
  }
  const split_table_data: TableWord[] = typewriter_data.slice(2) as TableWord[];
  const table_component = joinTable(split_table_data, splitChar);
  const headers: string[] = typewriter_data[1];
  // Fills out the first row with all the column names so that the table shows all columns from the start
  headers.forEach((element) => {
    if (!table_component.table_data[0][element]) {
      table_component.table_data[0][element] = '';
    }
  });
  return TABLE_COMPONENT_PREFIX + JSON.stringify(table_component);
}

function flatPathsToValues(
  articleData: ArticleData,
  flatPaths: string[],
  splitChar: string
): string[][] {
  const mapping = flatPaths.map((path) => {
    const pathParts = path.split('.');
    const value = pathParts.reduce((acc, part) => {
      return acc[part];
    }, articleData as any);

    // If the value is for a citation, we just pass through the array of strings.
    if (pathIsCitation(pathParts)) {
      return value as string[]; // value is already an array of strings
    } else {
      // If the value contains an AGENTPLAN or a non-table REACTCOMPONENT, that is the unit of iteration.
      // For tables, the unit of iteration is each word in each cell in the table.
      // Otherwise the unit of iteration is the word.
      if (value.includes('AGENTPLAN')) {
        return [value];
      } else if (value.startsWith(TABLE_COMPONENT_PREFIX)) {
        try {
          const table_component: TableComponent = JSON.parse(
            value.substring(TABLE_COMPONENT_PREFIX.length)
          );
          return convertTableToTypewriterFormat(table_component, splitChar);
        } catch {
          return [value];
        }
      } else if (value.includes('REACTCOMPONENT')) {
        return [value];
      } else {
        return splitWords(value, splitChar);
      }
    }
  });
  return mapping;
}

function assignValueToDeepObject(obj: any, pathParts: string[], value: any) {
  /* Assign the value to the object at the path specified by pathParts. */
  pathParts.forEach((part, i) => {
    if (i === pathParts.length - 1) {
      obj[part] = value;
    } else {
      obj = obj[part];
    }
  });
}

function assignTypewriterOutputsToArticleData(
  articleData: ArticleData,
  flatPaths: string[],
  flatValuesOutput: any[][],
  splitChar: string
): ArticleData {
  /* Assign the outputs from flatValuesOutput to the articleData object. Any paths in flatPaths that
      don't have corresponding output in flatValuesOutput should be set to the empty string, indicating
      that they have not been filled in byt the typewriter yet.
  */
  // Deep copy articleData
  const articleDataCopy = JSON.parse(
    JSON.stringify(articleData)
  ) as ArticleData;

  flatPaths.forEach((path, i) => {
    // get the path parts
    const pathParts = path.split('.');

    if (flatValuesOutput.length > i) {
      // The output value comes from flatValuesOutput

      // If it's a citation we passed it through orginally, so we just pass it through again
      let outputValue: string | string[];
      if (pathIsCitation(pathParts)) {
        outputValue = flatValuesOutput[i];
      } else if (
        flatValuesOutput[i][0] &&
        flatValuesOutput[i][0].startsWith(TABLE_COMPONENT_PREFIX)
      ) {
        // Handle when dealing with a table split up for the typewriter
        outputValue = convertTypewriterFormatToTableString(
          flatValuesOutput[i] as [string, string[], ...TableWord[]],
          splitChar
        );
      } else {
        outputValue = flatValuesOutput[i].join(splitChar);
      }

      // Assign the value to the articleData object
      assignValueToDeepObject(articleDataCopy, pathParts, outputValue);
    } else {
      // The output value is the empty string or array
      const outputValue = pathIsCitation(pathParts) ? [] : '';
      assignValueToDeepObject(articleDataCopy, pathParts, outputValue);
    }
  });

  return articleDataCopy;
}

function firstIndexWhereValuesDiffer(values1: any[], values2: any[]): number {
  /* Return the index of the first value in values1 that is different from the corresponding value in values2.
      If the arrays have a different length, treat the first index where they differ as the length of the shorter
      array. If the arrays are exactly the same, return the length of the array.
  */
  const minLength = Math.min(values1.length, values2.length);
  for (let i = 0; i < minLength; i++) {
    if (!isEqual(values1[i], values2[i])) {
      return i;
    }
  }
  return minLength;
}

interface UseArticleDataTypewriterOutput {
  writtenArticleData: ArticleData | undefined;
  running: boolean;
  done: boolean;
  currentLocation: string;
  setArticleDataToWrite: (_articleData: ArticleData) => void;
  resetArticleData: () => void;
  startTypewriter: () => void;
  stopTypewriter: () => void;
  typewriterWriteAll: () => void;
}

interface UseArticleDataTypewriterInput {
  timings: UseTypewriterTimings;
  splitType?: SplitType;
  useTimers?: () => Timers;
  onComplete?: () => void;
  onType?: () => void;
  strategy?: TimerStrategy;
}

export default function useArticleDataTypewriter({
  timings,
  splitType = 'word',
  useTimers,
  onComplete,
  onType,
  strategy,
}: UseArticleDataTypewriterInput): UseArticleDataTypewriterOutput {
  const {
    arraysWritten,
    running,
    done,
    currentArray,
    setArraysToWrite,
    startTypewriter,
    typewriterWriteAll,
    stopTypewriter,
  } = useTypewriter({
    timings,
    useTimers,
    onComplete,
    onType,
    timerStrategy: strategy,
  });

  const splitChar = splitType === 'word' ? ' ' : '';

  // These saved data should be refs because we don't need to update anything when they change. We only
  // use them to store data for later computation.
  const articleDataInput = useRef<ArticleData | undefined>(undefined);
  const flatPaths = useRef<string[] | undefined>(undefined);

  const flatValuesWritten = arraysWritten as string[][];
  const writtenArticleData = useMemo(() => {
    if (!articleDataInput.current || !flatPaths.current) {
      return undefined;
    }
    return assignTypewriterOutputsToArticleData(
      articleDataInput.current,
      flatPaths.current,
      flatValuesWritten,
      splitChar
    );
  }, [flatValuesWritten, splitChar]);

  const currentLocation = flatPaths.current
    ? flatPaths.current[currentArray]
    : '';

  const resetArticleData = useCallback(() => {
    articleDataInput.current = undefined;
    flatPaths.current = undefined;
    setArraysToWrite([], ResetType.All);
  }, [setArraysToWrite]);

  const setArticleDataToWrite = useCallback(
    (articleData: ArticleData) => {
      // This is the structure of the new articleData
      const newFlatPaths = articleDataToFlatPaths(articleData);
      const newFlatValues = flatPathsToValues(
        articleData,
        newFlatPaths,
        splitChar
      );

      // If there was a previous articleData, see if we need to go back to a previous state where
      // the new articleData and the old articleData are the same
      let resetData: ResetData | undefined = undefined;
      if (articleDataInput.current && flatPaths.current) {
        // Get the first index where the new flat paths and the old flat paths differ
        const firstIndexWherePathsDiffer = firstIndexWhereValuesDiffer(
          flatPaths.current,
          newFlatPaths
        );

        // Get the first index where the new flat values and the old flat values differ
        const oldFlatValues = flatPathsToValues(
          articleDataInput.current,
          flatPaths.current,
          splitChar
        );
        const firstIndexWhereFlatValuesDiffer = firstIndexWhereValuesDiffer(
          newFlatValues,
          oldFlatValues
        );

        // If either of these indices is less than the length of the old flat paths, we need to go back
        if (
          firstIndexWherePathsDiffer < oldFlatValues.length ||
          firstIndexWhereFlatValuesDiffer < oldFlatValues.length
        ) {
          if (firstIndexWherePathsDiffer <= firstIndexWhereFlatValuesDiffer) {
            // If we need to go back because of the paths, we start at the beginning of the new flat value
            resetData = {
              currentArray: firstIndexWherePathsDiffer,
              currentIndexInCurrentArray: 0,
            };
          } else {
            // If we need to go back because of the values, we start at the first index where the values differ
            const firstSubIndexWhereFlatValueItemsDiffer =
              firstIndexWhereValuesDiffer(
                newFlatValues[firstIndexWhereFlatValuesDiffer],
                oldFlatValues[firstIndexWhereFlatValuesDiffer]
              );

            resetData = {
              currentArray: firstIndexWhereFlatValuesDiffer,
              currentIndexInCurrentArray:
                firstSubIndexWhereFlatValueItemsDiffer,
            };
          }
        }
      }

      articleDataInput.current = articleData;
      flatPaths.current = newFlatPaths;
      setArraysToWrite(
        newFlatValues,
        resetData ? ResetType.Specific : ResetType.None,
        resetData
      );
    },
    [setArraysToWrite, splitChar]
  );

  return {
    writtenArticleData,
    running,
    done,
    currentLocation,
    setArticleDataToWrite,
    resetArticleData,
    startTypewriter,
    stopTypewriter,
    typewriterWriteAll,
  };
}
