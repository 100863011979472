import parse from 'html-react-parser';
import { Element as ScrollElement } from 'react-scroll';
import * as clipboard from 'clipboard-polyfill';

import { slugify } from '../utils';
import Bibliography from '../bibliography/Bibliography';
import AccordionView from './article_components/AccordionView';
import type { ArticleData } from '../types';
import type { RenderFunctionFilter } from './spans';

import { renderFigure } from './figures';
import { RenderSpan } from './spans';
import { Box, Skeleton, SxProps } from '@mui/material';
import { Calculator, UnsupportedCalculatorMessage } from '@xyla/calculators';
import { DrugsSection } from './article_components/DrugsSection';
import { GuidelinesSection } from './article_components/GuidelinesSection';
import { DocumentPreviewSection } from './article_components/DocumentPreviewSection';
import type { Updater } from 'use-immer';
import { StudyCallout } from './article_components/StudyCallout';

import deriveGeneratedArticleBase, {
  ParagraphContainerProps,
  SectionContainerProps,
  SectionTitleProps,
  SectionSkeletonProps,
  SectionImageProps,
} from '@xyla/openevidence-shared-functionality/src/article/deriveGeneratedArticleBase';

import {
  ACCORDION_SECTION_ID,
  CALCULATOR_SECTION_ID,
  CALCULATOR_UNSUPPORTED_SECTION_ID,
  DOCUMENT_PREVIEW_SECTION_ID,
  DRUGS_SECTION_ID,
  GUIDELINES_SECTION_ID,
  NEJM_LANDMARK_SECTION_ID,
  NEJM_REVIEW_SECTION_ID,
} from '@xyla/openevidence-shared-functionality/src/article/types';

const SectionSkeleton = ({
  sectionClassName,
  sectionBodyClassName,
}: SectionSkeletonProps) => (
  <Box className={sectionClassName}>
    <Skeleton
      animation='wave'
      height={50}
      width='40%'
      style={{ marginBottom: 0, marginTop: 50 }}
    />
    <Box className={sectionBodyClassName}>
      {Array.from({ length: 8 }, (_, i) => (
        <Skeleton
          key={i}
          animation='wave'
          height={25}
          width='100%'
          style={{ marginBottom: 0 }}
        />
      ))}
      <Box>
        <Skeleton
          animation='wave'
          variant='rectangular'
          height={290}
          width='70%'
          style={{ margin: '20px auto 50px' }}
        />
      </Box>
    </Box>
  </Box>
);

function SectionTitle({
  titleKey,
  title,
  name,
  styles,
  index,
  cursorSxFor,
}: SectionTitleProps) {
  const copyLinkToClipboard = () => {
    const link = window.location.href + '#' + name;
    clipboard.writeText(link);
  };

  const sectionTitleSx = cursorSxFor?.(
    `articlesection_set.${index}.section_title`
  );

  return (
    <ScrollElement name={name}>
      <Box
        className={
          styles.section_title +
          ' sectionTitle brandable--article-section-title'
        }
        key={titleKey}
        sx={sectionTitleSx}
      >
        {parse(title)}
        <a
          className='sectionTitle_link'
          href={'#' + name}
          onClick={copyLinkToClipboard}
        >
          <svg
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#a)'>
              <path
                d='M1.093 9.616a3.746 3.746 0 0 0 0 5.29 3.746 3.746 0 0 0 5.29 0l2.94-2.938a3.746 3.746 0 0 0 0-5.29.623.623 0 1 0-.882.881 2.475 2.475 0 0 1 0 3.527L5.5 14.025a2.475 2.475 0 0 1-3.526 0 2.475 2.475 0 0 1 0-3.527l2.792-2.792a.624.624 0 1 0-.882-.882L1.093 9.616Zm5.584-5.584a3.746 3.746 0 0 0 0 5.29.623.623 0 1 0 .882-.881 2.475 2.475 0 0 1 0-3.527l2.94-2.939a2.475 2.475 0 0 1 3.526 0 2.475 2.475 0 0 1 0 3.527l-2.792 2.792a.623.623 0 1 0 .882.882l2.792-2.793a3.746 3.746 0 0 0 0-5.29 3.746 3.746 0 0 0-5.29 0l-2.94 2.94Z'
                fill='currentColor'
              />
            </g>
          </svg>
        </a>
      </Box>
    </ScrollElement>
  );
}

function SectionImage({ image, styles }: SectionImageProps) {
  return (
    <div className={styles.image_wrapper}>
      <img src={image} alt='' />
    </div>
  );
}

function SectionContainer({
  /** `children` should be paragraphsInSectionList: JSX.Element[] */
  children,
  styles,
  sectionData,
  sectionIndex,
  articleData,
  setArticleData,
  questionIndex,
  conversationLength,
  sectionTitleElement,
  sectionImageElement,
  sectionClassName,
}: SectionContainerProps) {
  const sectionBodyClassName = styles.section_body;

  const paragraphsToSectionBodyElement = (paragraphs: JSX.Element[]) => (
    <div className={sectionBodyClassName}>{paragraphs}</div>
  );

  const sectionBodyElement = paragraphsToSectionBodyElement(children);

  let sectionContent;
  if (sectionData.identifier === GUIDELINES_SECTION_ID) {
    sectionContent = (
      <GuidelinesSection
        section_title={sectionData.section_title}
        {...sectionData.metadata}
      >
        {sectionBodyElement}
      </GuidelinesSection>
    );
  } else if (sectionData.identifier === DRUGS_SECTION_ID) {
    sectionContent = (
      <DrugsSection
        section_title={sectionData.section_title}
        {...sectionData.metadata}
      >
        {sectionBodyElement}
      </DrugsSection>
    );
  } else if (sectionData.identifier === DOCUMENT_PREVIEW_SECTION_ID) {
    sectionContent = (
      <DocumentPreviewSection
        sectionData={sectionData}
        originalArticleData={articleData}
      >
        {sectionBodyElement}
      </DocumentPreviewSection>
    );
  } else if (sectionData.identifier === CALCULATOR_SECTION_ID) {
    sectionContent = (
      <>
        <Calculator
          setArticleData={setArticleData as Updater<ArticleData>}
          sectionData={sectionData}
          sectionIndex={sectionIndex}
          paragraphsToSectionBodyElement={paragraphsToSectionBodyElement}
          // If the Calculator isn't in the most recent answer, disable it.
          disabled={
            questionIndex !== undefined &&
            conversationLength !== undefined &&
            questionIndex < conversationLength - 1
          }
        >
          {children}
        </Calculator>
      </>
    );
  } else if (sectionData.identifier === CALCULATOR_UNSUPPORTED_SECTION_ID) {
    sectionContent = <UnsupportedCalculatorMessage sectionData={sectionData} />;
  } else if (
    sectionData.identifier === NEJM_LANDMARK_SECTION_ID ||
    sectionData.identifier === NEJM_REVIEW_SECTION_ID
  ) {
    sectionContent = (
      <StudyCallout
        section_title={sectionData.section_title}
        {...sectionData.metadata}
      >
        {sectionBodyElement}
      </StudyCallout>
    );
  } else {
    sectionContent = (
      <div>
        {sectionTitleElement}
        {sectionBodyElement}
        {sectionImageElement}
      </div>
    );
  }

  let section = (
    <div className={sectionClassName} key={`${sectionIndex}-${sectionIndex}`}>
      {sectionData.identifier === ACCORDION_SECTION_ID ? (
        <AccordionView>{sectionContent}</AccordionView>
      ) : (
        sectionContent
      )}
    </div>
  );

  return section;
}

function ParagraphContainer({
  children,
  title,
  styles,
  paragraphTitleSx,
}: ParagraphContainerProps) {
  return (
    <div
      className={styles.paragraph_div + ' brandable--article-paragraph-title'}
    >
      {title !== '' ? (
        <Box className={styles.paragraph_title} sx={paragraphTitleSx}>
          {title}
        </Box>
      ) : null}
      <div className={styles.paragraph_p}>{children}</div>
    </div>
  );
}

function FigureParagraphContainer({
  children,
  title,
  styles,
  captionSpans,
}: ParagraphContainerProps) {
  return (
    <div className={styles.figure_paragraph_div}>
      {title === '' ? null : (
        <div
          className={
            styles.paragraph_title + ' brandable--article-paragraph-title'
          }
        >
          {title}
        </div>
      )}
      <div className={styles.figure_paragraph_inner_div}>{children}</div>
      {captionSpans && captionSpans.length > 0 ? (
        <div className={styles.figure_caption_p}>{captionSpans}</div>
      ) : null}
    </div>
  );
}
interface DeriveGeneratedArticleProps {
  data: ArticleData;
  linesToSkip: number;
  notableStudyData: Record<string, unknown>;
  num_conditions: number;
  num_interventions: number;
  styles: Record<string, string>;
  disableCitationLinks: boolean;
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  additionalComponentData: Record<string, unknown>;
  smallVersion?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
  questionIndex?: number;
  conversationLength?: number;
  /** Updater fn passed in from parent component, since we can't use a react hook here */
  setArticleData?: Updater<ArticleData>;
}

export default function deriveGeneratedArticle({
  data,
  linesToSkip,
  notableStudyData,
  num_conditions,
  num_interventions,
  styles,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
  smallVersion = false,
  cursorSxFor = () => ({}),
  questionIndex,
  conversationLength,
  setArticleData,
}: DeriveGeneratedArticleProps) {
  const { keyFindingsSection, references, generatedArticle, sectionTitles } =
    deriveGeneratedArticleBase({
      data,
      linesToSkip,
      notableStudyData,
      numConditions: num_conditions,
      numInterventions: num_interventions,
      styles,
      disableCitationLinks,
      additionalRenderFunctions,
      additionalComponentData,
      smallVersion,
      cursorSxFor,
      questionIndex,
      conversationLength,
      setArticleData,
      slugify,
      renderers: {
        renderSpan: RenderSpan, // RenderSpan is stateful! should be refactored
        renderFigure,
        SectionContainer,
        ParagraphContainer,
        FigureParagraphContainer,
        Bibliography,
        SectionTitle,
        SectionImage,
        SectionSkeleton,
      },
    });

  return {
    keyFindingsSection,
    references,
    generatedArticle,
    sectionTitles,
  };
}
