/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OEUserProfileVerifyNpi
 */
export interface OEUserProfileVerifyNpi {
    /**
     * 
     * @type {boolean}
     * @memberof OEUserProfileVerifyNpi
     */
    verified: boolean;
}

/**
 * Check if a given object implements the OEUserProfileVerifyNpi interface.
 */
export function instanceOfOEUserProfileVerifyNpi(value: object): value is OEUserProfileVerifyNpi {
    if (!('verified' in value) || value['verified'] === undefined) return false;
    return true;
}

export function OEUserProfileVerifyNpiFromJSON(json: any): OEUserProfileVerifyNpi {
    return OEUserProfileVerifyNpiFromJSONTyped(json, false);
}

export function OEUserProfileVerifyNpiFromJSONTyped(json: any, ignoreDiscriminator: boolean): OEUserProfileVerifyNpi {
    if (json == null) {
        return json;
    }
    return {
        
        'verified': json['verified'],
    };
}

export function OEUserProfileVerifyNpiToJSON(json: any): OEUserProfileVerifyNpi {
    return OEUserProfileVerifyNpiToJSONTyped(json, false);
}

export function OEUserProfileVerifyNpiToJSONTyped(value?: OEUserProfileVerifyNpi | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'verified': value['verified'],
    };
}

