import { Box, SxProps, Theme, Typography } from '@mui/material';
import { DocumentDetails } from './DocumentDetails';
import { DocumentDetailsList } from './DocumentDetailsList';
import { CalloutCitedDocument } from './types';

export interface FooterProps {
  attribution?: string;
  documents?: CalloutCitedDocument[];
  documentDetailsListPreviewText?:
    | string
    | ((documents: CalloutCitedDocument[]) => string);
  sx?: SxProps<Theme>;
}

export const Footer = ({
  attribution,
  documents,
  documentDetailsListPreviewText,
  sx = {},
}: FooterProps) => (
  <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'column',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {attribution && (
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: 'inherit',
          color: 'grey.700',
        }}
      >
        {attribution}
      </Typography>
    )}
    {documents &&
      documents.length > 0 &&
      (documents.length === 1 ? (
        <DocumentDetails {...documents[0]} />
      ) : (
        <DocumentDetailsList
          documents={documents}
          previewText={documentDetailsListPreviewText}
        />
      ))}
  </Box>
);
