import { Box, SxProps, Typography } from '@mui/material';
import { PublicationCard } from './PublicationCard';
import {
  PublicationQuotationProps,
  getQuoteAttributionElements,
} from '@xyla/openevidence-shared-functionality';
import { PublicationCitation } from './PublicationCitation';

// Additional props injected in spans.tsx::RenderSpan
type PublicationQuotationWithUIProps = PublicationQuotationProps & {
  hidePublicationFrame?: boolean;
};

export function PublicationQuotation({
  data,
}: {
  data: PublicationQuotationWithUIProps;
}) {
  const { text, full_author_list, affiliations, hidePublicationFrame } = data;

  const { authorString, authorSuffix, affilationString, affilationSuffix } =
    getQuoteAttributionElements(full_author_list, affiliations);

  const quotationContent = (
    <>
      <Box
        sx={{
          mb: 1.5,
          position: 'relative',
          pl: 2,
          lineHeight: 1.5,
          // Block quote bar on left side
          '&::before': {
            content: '""',
            display: 'block',
            width: '4px',
            backgroundColor: 'grey.400',
            opacity: 0.8,
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
          },
        }}
      >
        {/* Quotation marks */}
        <Box
          sx={{
            '&::before, &::after': {
              display: 'inline-block',
              fontSize: '2rem',
              lineHeight: '8px',
              color: 'grey.500',
              transform: 'translateY(7px)',
            },
            '&::before': {
              content: '"“"',
              marginRight: '2px',
            },
            '&::after': {
              content: '"”"',
              marginLeft: '2px',
            },
          }}
        >
          {/* Actual quotation text */}
          {text}
        </Box>
        {/* Author list */}
        <QuotationAttribution sx={{ mt: 1 }}>
          <strong>{authorString}</strong>
          {authorSuffix && (
            <>
              , <em>{authorSuffix}</em>
            </>
          )}
          {affilationString && <>, {affilationString}</>}
          {affilationSuffix}
        </QuotationAttribution>

        {/* Additional citation information */}
        {!hidePublicationFrame && (
          <PublicationCitation rich_citation_data={data.rich_citation_data} />
        )}
      </Box>
    </>
  );

  return hidePublicationFrame ? (
    quotationContent
  ) : (
    <PublicationCard>{quotationContent}</PublicationCard>
  );
}

/** Displays the attribution. Just prepends an emdash to the children. */
function QuotationAttribution({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return <Typography sx={sx}>&mdash; {children}</Typography>;
}
