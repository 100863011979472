import { Fragment } from 'react';
import { Link } from 'react-scroll';
import clsx from 'clsx';

import type { Citation, LegacyCitation } from './types';
import { findCitationIndex } from '@xyla/openevidence-shared-functionality/src/article/citations';

// When we scroll to an element, offset global header height
export const CITATION_SCROLL_OFFSET = -100;

// Suffix used to distinguish citation/references from other react-scroll links
export const CITATION_LINK_SUFFIX = '_refLink';

export class CtrlLink extends Link {
  handleClick = (event: any) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(window.location.pathname + '#' + this.props.to, '_blank');
    } else {
      (this as any).scrollTo(this.props.to, this.props);
    }
  };
}

export function ct_to_citation_string(ct: LegacyCitation): string {
  let pubmed_url = 'https://pubmed.ncbi.nlm.nih.gov/' + ct.pmid;
  let title_string = `<a target="_blank" href="${pubmed_url}">${ct.title}</a>`;
  let citation_string = ct.citation.replace(ct.title, title_string);
  return citation_string;
}

export function CitationSpan({
  citations,
  references,
  prefix,
  styles,
  disableLinks = false,
  questionIndex,
  askWrapperContainerId,
}: {
  /** The citations that we need to display in this span, e.g. `[1][3-4]` */
  citations: Citation[];
  /** All available references in the article */
  references: Citation[];
  prefix: string;
  styles: Record<string, string>;

  // Set to true to disable links to references
  disableLinks?: boolean;

  questionIndex?: number;
  askWrapperContainerId?: string;
}) {
  let prev_start: number | null = null;
  let last_number: number | null = null;
  const citation_elements: JSX.Element[] = [];

  function end_last_bracket() {
    if (last_number === null || prev_start === null) return;
    let ref_str = null;
    if (last_number - prev_start > 0)
      ref_str = `[${prev_start}-${last_number}]`;
    else ref_str = `[${prev_start}]`;

    const key = `${prefix}_${prev_start}_citelink`;

    let elementLink = disableLinks ? (
      <Fragment key={key}>{ref_str}</Fragment>
    ) : (
      <CtrlLink
        key={key}
        to={`${questionIndex}_${prev_start}${CITATION_LINK_SUFFIX}`}
        offset={CITATION_SCROLL_OFFSET}
        containerId={askWrapperContainerId}
      >
        {ref_str}
      </CtrlLink>
    );
    citation_elements.push(elementLink);
  }

  for (let i = 0; i < citations.length; i++) {
    // The number for this citation that will be shown in the UI
    const number = findCitationIndex(references, citations[i]) + 1;

    let end_last = false;
    let start_new = false;
    if (last_number == null) {
      start_new = true;
    } else if (number - last_number > 1) {
      start_new = true;
      end_last = true;
    }

    // Handle ending a bracket
    if (end_last) {
      end_last_bracket();
    }
    // Handle starting a new bracket
    if (start_new) {
      prev_start = number;
    }
    last_number = number;
  }
  if (prev_start != null) end_last_bracket();

  return (
    <span
      key={`${prefix}_${prev_start}_citelink`}
      className={clsx(
        disableLinks ? styles.citations_disabled : styles.citations,
        'brandable--citation'
      )}
    >
      {citation_elements}
    </span>
  );
}
