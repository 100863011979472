import { useState } from 'react';
import {
  Box,
  Fade,
  IconButton,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useArticleContext } from '../contexts';
import {
  AnswerEngagedExternalLinkListOpenedEventProps,
  ExternalSearchInViewEventProps,
  useExternalTracking,
} from '@xyla/analytics';
import { LocalizedText } from '@xyla/util';
import { ExternalSearchResult } from '../types';
import { SearchLink } from './SearchLink';
import { useInView } from 'react-intersection-observer';

interface ArticleReferencesProps {
  results: ExternalSearchResult[];
  startExpanded?: boolean;
  maxLinksCollapsed?: number;
  maxLinks?: number;
}

export function WebSearchResults({
  results,
  startExpanded = false,
  maxLinks,
  maxLinksCollapsed = 2,
}: ArticleReferencesProps): JSX.Element | null {
  const { trackMixpanelEvent } = useExternalTracking();
  const { trackArticleEvent } = useArticleContext();
  const [viewCount, setViewCount] = useState(0);
  const { ref } = useInView({
    // threshold 80% because this ensures at least the first link is fully visible
    threshold: 0.8,
    onChange: (inView) => {
      if (inView) {
        // We don't have article data when this component is shown during an error, so default to regular tracking event
        const trackFn = trackArticleEvent ?? trackMixpanelEvent;
        trackFn('external_search_in_view', {
          view_count: viewCount + 1,
          links: results.map((result) => result.url),
        } satisfies ExternalSearchInViewEventProps);
        setViewCount(viewCount + 1);
      }
    },
  });
  const maxLinksExpanded = maxLinks ?? results.length;
  const [numLinksToShow, setNumLinksToShow] = useState(
    startExpanded ? maxLinksExpanded : maxLinksCollapsed
  );

  const isExpanded = numLinksToShow > maxLinksCollapsed;
  const isCollapsedMinimized = maxLinksCollapsed === 0;

  const handleChange = () => {
    if (isExpanded) {
      trackArticleEvent?.('answer_engaged', {
        action: 'External links list opened',
      } satisfies AnswerEngagedExternalLinkListOpenedEventProps);
    }
    setNumLinksToShow(isExpanded ? maxLinksCollapsed : maxLinksExpanded);
  };

  if (!results || results.length === 0) {
    return null;
  }

  const collapseButton = (
    <IconButton
      onClick={handleChange}
      sx={{
        width: '30px',
        height: '30px',
        marginLeft: 'auto',
      }}
      disableRipple
    >
      <ExpandMoreIcon
        sx={{
          transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease-in-out',
        }}
      />
    </IconButton>
  );

  return (
    <Paper
      ref={ref}
      elevation={0}
      className={'brandable--follow-up-questions'}
      sx={{
        background: 'none !important',
        border: '1px solid hwb(0 96% 10%)',
        borderRadius: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          textAlign: 'left',
          padding:
            isCollapsedMinimized && !isExpanded
              ? { xs: '1em 1em 0em 1em' }
              : { xs: '1.5em 1.5em 0.5em 1.5em' },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className='brandable--follow-up-questions--title-bar'
          sx={{ display: 'flex' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <SearchIcon fontSize='small' />

            <Typography sx={{ fontSize: '1em', fontWeight: 500 }} variant='h2'>
              <LocalizedText str='External Search Links' />
            </Typography>
          </Box>
          {!isExpanded && maxLinksCollapsed === 0 && collapseButton}
        </Box>
        <Typography variant='body2' sx={{ mt: 1, color: 'text.secondary' }}>
          These external web sources may be relevant to your question. They are
          not used in the answer.
        </Typography>
        <Box sx={{ mt: 1 }}>
          {results.slice(0, numLinksToShow).map((searchResult, i) => (
            <Fade in timeout={500} key={i}>
              <Box mb={{ sm: 1 }}>
                <SearchLink
                  description={searchResult.snippet}
                  url={searchResult.url}
                  siteName={searchResult.siteName}
                  title={searchResult.name}
                  // chip={searchResult.sponsored ? 'sponsored' : undefined}
                  maxDescriptionLines={2}
                  variant='minimal'
                />
                {i < numLinksToShow - 1 && i < results.length - 1 && (
                  <Divider sx={{ mt: { sm: 1 } }} />
                )}
              </Box>
            </Fade>
          ))}
        </Box>
        {(maxLinksCollapsed > 0 || isExpanded) &&
          results.length > maxLinksCollapsed && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              {collapseButton}
            </Box>
          )}
      </Box>
    </Paper>
  );
}
