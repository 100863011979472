import { Card, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { NEJMIcon } from '../icons/NEJMIcon';
import { NEJM_LICENSE } from '@xyla/openevidence-shared-functionality';

const PUBLICATIONS = {
  NEJM: {
    logo: <NEJMIcon />,
    license: NEJM_LICENSE,
  },
};

/**
 * Card container for publication components. Renders the publication inside a
 * card with the logo and license info.
 */
export function PublicationCard({
  children,
  publicationId = 'NEJM',
}: {
  children?: ReactNode;
  publicationId?: keyof typeof PUBLICATIONS;
}) {
  if (!PUBLICATIONS[publicationId]) {
    throw new Error(`ProgrammingError: Invalid publication ${publicationId}`);
  }

  return (
    <Card
      variant='outlined'
      sx={{
        mt: 2,
        mb: 2,
        p: 2,
        backgroundColor: 'grey.50',
        borderRadius: 2,
        color: '#424242 !important',
        position: 'relative',
      }}
    >
      {/* Header - NEJM logo */}
      <Box sx={{ height: '28px', display: 'inline-block', mb: 2 }}>
        {PUBLICATIONS[publicationId].logo}
      </Box>

      {/* Body - content from parent */}
      {children}

      {/* Footer - license info */}
      <Typography
        sx={{
          color: 'grey.600',
          fontSize: '0.75rem',
          mt: 0, // Override default margin styles
        }}
      >
        {PUBLICATIONS[publicationId].license}
      </Typography>
    </Card>
  );
}
