// Add webpack require.context type because it is not getting picked up automatically for some reason
declare const require: {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ) => any;
};

// Use Webpack's require.context to load all calculator modules
const calculatorsContext = require.context(
  './calculator_registry',
  false,
  /\.tsx$/
);

export function loadCalculators() {
  calculatorsContext.keys().forEach((filename: string) => {
    calculatorsContext(filename);
  });
}
