'use client';

import { ThumbDownAlt, ThumbUpAlt, Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Snackbar,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { AnswerEngagedReferencesFeedbackEventProps } from '@xyla/analytics';
import { useArticleContext } from '../contexts';
import { useState } from 'react';
import { useImmer } from 'use-immer';

const buttonStyle = {
  color: (theme: Theme) => theme.palette.grey[600],
  minWidth: '25px',
  minHeight: '25px',
  '> svg': { transform: 'scale(0.8)' },
};

const POSITIVE_CATEGORIES = [
  'Highly relevant',
  'Recent',
  'Reliable source',
  'Helpful information',
  'Obscure, but excellent',
];
const NEGATIVE_CATEGORIES = [
  'Not relevant',
  'Outdated',
  'Questionable source',
  'Incorrect information',
  'Better source available (please specify)',
];

interface FeedbackCategory {
  label: string;
  checked: boolean;
}

export const ReferenceFeedbackButtons = ({
  referenceIndex,
  show = true,
  buttonSx,
}: {
  referenceIndex: number;
  show?: boolean;
  buttonSx?: SxProps;
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isPositive, setIsPositive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [categories, setCategories] = useImmer<FeedbackCategory[]>([]);

  const {
    feedbackSuccessText,
    enableStickyAskBar,
    recordReferenceFeedback,
    trackArticleEvent,
  } = useArticleContext();

  if (recordReferenceFeedback === undefined) {
    return null;
  }

  const showToastMessage = (message?: string) => {
    if (!message) {
      message =
        feedbackSuccessText ?? 'Thank you for helping us improve OpenEvidence!';
    }
    setToastMessage(message);
    setShowToast(true);
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const startDialog = (isPositive: boolean) => {
    // Init a list with all labels set to false. Needed for the Checkboxes to be controlled components
    const categoriesList = isPositive
      ? POSITIVE_CATEGORIES
      : NEGATIVE_CATEGORIES;
    const categories = categoriesList.map((category) => ({
      label: category,
      checked: false,
    }));

    setIsPositive(isPositive);
    setCategories(categories);
    setOpenModal(true);
  };

  const onSubmit = async () => {
    setOpenModal(false);
    setFeedbackSubmitted(true);
    const selectedCategories = categories
      .filter((c) => c.checked)
      .map((c) => c.label);

    try {
      await recordReferenceFeedback(
        isPositive,
        feedbackText,
        selectedCategories,
        referenceIndex
      );
      showToastMessage();
    } catch (errorMsg) {
      // See comment in HotjarBinaryResponse:58
      console.error(errorMsg);
      showToastMessage();
    }

    trackArticleEvent?.('answer_engaged', {
      action: 'Reference feedback sent',
      is_feedback_positive: isPositive,
      feedback_text: feedbackText,
      feedback_categories: selectedCategories,
    } satisfies AnswerEngagedReferencesFeedbackEventProps);
  };

  return (
    <>
      {show && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* Thumbs up/down buttons */}
          {(!feedbackSubmitted || isPositive) && (
            <Tooltip title='Helpful'>
              <Button
                color='inherit'
                size='small'
                sx={{ ...buttonStyle, ...buttonSx }}
                disabled={(openModal || feedbackSubmitted) && isPositive}
                onClick={() => {
                  startDialog(true);
                }}
              >
                <ThumbUpAlt fontSize='small' />
              </Button>
            </Tooltip>
          )}
          {(!feedbackSubmitted || !isPositive) && (
            <Tooltip title='Not Helpful'>
              <Button
                color='inherit'
                size='small'
                sx={{ ...buttonStyle, ...buttonSx }}
                disabled={(openModal || feedbackSubmitted) && !isPositive}
                onClick={() => {
                  startDialog(false);
                }}
              >
                <ThumbDownAlt fontSize='small' />
              </Button>
            </Tooltip>
          )}
        </Box>
      )}
      {/* Additional feedback form */}
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <DialogTitle sx={{ position: 'relative' }}>
          <Box sx={{ maxWidth: '90%' }}>Help Us Improve Our References</Box>
          <IconButton
            onClick={() => {
              setOpenModal(false);
            }}
            sx={{
              position: 'absolute',
              top: '12px',
              right: '12px',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id='feedback-modal'>
            Provide additional feedback (optional)
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            multiline
            margin='normal'
            value={feedbackText}
            onChange={(event: any) => {
              setFeedbackText(event.target.value);
            }}
            aria-labelledby='feedback-modal'
          />
          <FormGroup sx={{ display: 'inline-flex' }}>
            {categories.map((category, i) => (
              <FormControlLabel
                key={category.label}
                label={category.label}
                control={
                  <Checkbox
                    checked={categories[i].checked}
                    onChange={(event) =>
                      setCategories((draft) => {
                        draft[i].checked = event.target.checked;
                      })
                    }
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            onClick={onSubmit}
            variant='outlined'
            color='inherit'
            sx={{ textTransform: 'none' }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* User message */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showToast}
        onClose={handleToastClose}
        autoHideDuration={3000}
        sx={{
          bottom: enableStickyAskBar ? { xs: '78px', sm: '112px' } : null,
        }}
      >
        <Alert
          className='brandable--feedback-success-banner'
          severity='success'
          onClose={handleToastClose}
          sx={{ boxShadow: 3, borderRadius: 1 }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
