import countryData from './country-tiers.json';

export enum CountryTier {
  TIER_1 = 'tier-1',
  TIER_2 = 'tier-2',
  TIER_3 = 'tier-3',
  TIER_4 = 'tier-4',
  TIER_5 = 'tier-5',
  TIER_6 = 'tier-6',
}

/** Returns the CountryTier for the given country code */
export function getCountryTier(country?: string): CountryTier | null {
  // Countries are read from request headers injected by Vercel, but there may
  // be rare cases where the country doesn't exist. Handle gracefully.
  if (!country) {
    return null;
  }
  for (const [tier, countries] of Object.entries(countryData)) {
    if (countries.includes(country)) {
      return tier as CountryTier;
    }
  }
  return null;
}

/** Returns all countries in the given tier */
export function getCountriesInTier(tier: CountryTier): string[] {
  return countryData[tier];
}
