import { CalloutCitedDocument } from '../../types';

export const NEJM_LICENSE =
  'Used under license from The New England Journal of Medicine.';

/** Ensure we show exactly one period after each portion of the citation. */
export function getTrailingString(s: string | null) {
  if (!s) {
    return '';
  } else if (s.endsWith('.')) {
    return ' ';
  } else {
    return '. ';
  }
}
/** Equivalent to python string rstrip. */
export function rtrim(s: string, chars: string): string {
  const charSet = new Set(chars);

  let i = s.length;
  while (i > 0 && charSet.has(s[i - 1])) {
    i--;
  }
  return s.slice(0, i);
}

/** Force the string to end with a period. */
export function endWithPeriod(referenceString: string): string {
  return rtrim(referenceString, '. ') + '.';
}

/** Parse an authorList and affiliations into citation elements */
export function getQuoteAttributionElements(
  authorList?: string[],
  affiliations?: string[]
) {
  if (!authorList || !affiliations) {
    return {
      authorString: '',
      authorSuffix: '',
      affilationString: '',
      affilationSuffix: '',
    };
  }

  // Show just the first author + 'et al', and first affiliation + 'and other institutions'
  return {
    authorString: authorList[0],
    authorSuffix: authorList.length > 1 ? ` et al.` : ``,
    affilationString: affiliations[0],
    affilationSuffix: affiliations.length > 1 ? ` and other institutions` : ``,
  };
}

export function getCalloutDocumentAttributionElements({
  title,
  authors,
  publication_info,
}: CalloutCitedDocument) {
  // Lump most of the non-title citation details together.
  let extendedCitation = '';
  if (authors) {
    extendedCitation += authors;
  }
  if (publication_info) {
    extendedCitation += publication_info;
  }

  // Title stands alone because it will be hrefed and styled differently.
  // If we've got any citation info beyond the title, it needs to end with
  // a period to not look awkward.
  const titleForCitation = extendedCitation ? endWithPeriod(title) : title;

  return {
    titleForCitation,
    extendedCitation,
  };
}
