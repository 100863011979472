import { ASKOE__ALLOW_HIPAA_EXPERIENCE } from '../featureFlags';
import { UserInfo } from '../users/types';
import { Waffle } from '../waffle/useWaffle';
import { BAA_BANNER_ID } from './baaBanner';

export function phiSafeBannerEnabledClientSide(
  waffle: Waffle,
  userData: UserInfo | null,
  country: string,
  acknowledgedBannerId: string | null
) {
  // When the feature flag is active, the user has signed the BAA but not through the banner,
  // and the user is in the US, show the banner
  return (
    waffle.is_flag_active(ASKOE__ALLOW_HIPAA_EXPERIENCE) &&
    userData?.isBAASigned === true &&
    acknowledgedBannerId !== BAA_BANNER_ID &&
    country === 'US'
  );
}

export const PHI_SAFE_BANNER_ID = 'HIPAA_PHI_SAFE_BANNER';
export const PHI_SAFE_DISMISS_BANNER_STORAGE_KEY = 'phiSafeBannerDismissedData';

export const PHI_SAFE_BANNER_MESSAGE =
  'Protected health information (PHI) will be securely processed under HIPAA.';
