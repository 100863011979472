import { Box, IconButton, Typography, Dialog } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import { PublicationCard } from './PublicationCard';
import { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { PublicationFigureProps } from '@xyla/openevidence-shared-functionality';
import { PublicationCitation } from './PublicationCitation';

// Additional props injected in spans.tsx::RenderSpan
type PublicationFigureWithUIProps = PublicationFigureProps & {
  hidePublicationFrame?: boolean;
};

/**
 * Renders a figure and its caption. This is distinct from `renderFigure`,
 * whose purpose is lost to the annals of history.
 */
export function PublicationFigure({
  data,
}: {
  data: PublicationFigureWithUIProps;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Random ID for accessibility labels
  // TODO(andy): Fix hydration errors
  const captionId =
    'figure-caption-' + Math.random().toString(36).substring(2, 15);

  const figureContent = (
    <>
      {/* Expand to full screen button. Positioned relative to parent. */}
      <IconButton
        onClick={() => setIsExpanded(true)}
        sx={{
          position: 'absolute',
          top: data.hidePublicationFrame ? 4 : 16,
          right: data.hidePublicationFrame ? 4 : 16,
          border: 1,
          borderColor: 'grey.400',
          borderRadius: 1,
          backgroundColor: 'white',
        }}
        size='small'
        aria-label='Expand figure to full screen'
      >
        <OpenInFullIcon fontSize='inherit' />
      </IconButton>
      {/* Actual figure */}
      <Box
        component='img'
        src={data.url}
        sx={{
          width: '100%',
          display: 'block',
          maxHeight: '600px',
          objectFit: 'contain',
          my: 1,
        }}
        aria-labelledby={captionId}
      />
      {/* Figure caption */}
      <Box
        sx={{
          color: 'grey.600',
          fontSize: '0.875rem',
          lineHeight: 1.4,
          textAlign: 'center',
          px: { xs: 1, sm: 2 },
        }}
      >
        <Typography
          component='span'
          sx={{ fontWeight: 600, fontSize: 'inherit', lineHeight: 'inherit' }}
        >
          {data.name}.{' '}
        </Typography>
        <Typography
          component='span'
          id={captionId}
          sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}
        >
          {data.caption}
        </Typography>
      </Box>
    </>
  );

  const fullscreenImageViewerDialog = (
    <Dialog
      open={isExpanded}
      onClose={() => setIsExpanded(false)}
      maxWidth={false}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'relative',
        },
      }}
    >
      <IconButton
        onClick={() => setIsExpanded(false)}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: 'grey.700',
          color: 'white',
          opacity: 0.8,
          '&:hover': {
            backgroundColor: 'grey.400',
          },
          zIndex: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
      <TransformWrapper
        initialScale={1}
        minScale={0.8}
        maxScale={4}
        centerOnInit={true}
      >
        <TransformComponent
          wrapperStyle={{
            // TODO(andy): Tweak this or the margin/width/padding styles on the PaperProps container
            width: '90vw',
            height: '90vh',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            position: 'relative',
          }}
        >
          <Box
            component='img'
            src={data.url}
            sx={{
              // Match these with wrapperStyle above, so image fits container on open.
              maxHeight: '90vh',
              maxWidth: '90vw',
              objectFit: 'contain',
            }}
          />
        </TransformComponent>
      </TransformWrapper>
    </Dialog>
  );

  return (
    <>
      {data.hidePublicationFrame ? (
        <Box position='relative'>{figureContent}</Box>
      ) : (
        <PublicationCard>
          {figureContent}
          <PublicationCitation
            rich_citation_data={data.rich_citation_data}
            sx={{ mt: 1 }}
          />
        </PublicationCard>
      )}
      {fullscreenImageViewerDialog}
    </>
  );
}
