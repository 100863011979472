import { Box, Link, SxProps, Typography } from '@mui/material';
import {
  endWithPeriod,
  formatArticleDate,
  parseArticleDate,
} from '../../../utils';
import { CitationDetail } from '../../../types';
import { getTrailingString } from '@xyla/openevidence-shared-functionality';

/** Returns the citation info for a publication. Includes title, journal short name, and date. */
export function PublicationCitation({
  rich_citation_data,
  sx,
}: {
  rich_citation_data: CitationDetail;
  sx?: SxProps;
}) {
  const dateDisplay = formatArticleDate(
    parseArticleDate(rich_citation_data.dt_published || undefined)
  );

  return (
    <Box sx={{ fontSize: '0.875rem', color: 'grey.600', ...sx }}>
      <Typography
        component={Link}
        href={rich_citation_data.href}
        target='_blank'
        rel='noopener noreferrer'
        sx={{ display: 'inline-block', fontSize: 'inherit' }}
      >
        {endWithPeriod(rich_citation_data.title)}
      </Typography>{' '}
      <Typography
        component='span'
        sx={{
          display: 'inline-block',
          color: (theme) => `${theme.palette.grey[600]} !important`,
          fontSize: 'inherit',
        }}
      >
        {rich_citation_data.journal_short_name}
        {getTrailingString(rich_citation_data.journal_short_name)}
        {dateDisplay}
        {getTrailingString(dateDisplay)}
      </Typography>
    </Box>
  );
}
