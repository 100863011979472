/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DBIdentificationRequest
 */
export interface DBIdentificationRequest {
    /**
     * 
     * @type {string}
     * @memberof DBIdentificationRequest
     */
    db_occupation: string;
    /**
     * 
     * @type {string}
     * @memberof DBIdentificationRequest
     */
    db_name: string;
    /**
     * 
     * @type {string}
     * @memberof DBIdentificationRequest
     */
    db_eid: string;
    /**
     * 
     * @type {string}
     * @memberof DBIdentificationRequest
     */
    db_specialty: string;
    /**
     * 
     * @type {string}
     * @memberof DBIdentificationRequest
     */
    db_npi: string;
}

/**
 * Check if a given object implements the DBIdentificationRequest interface.
 */
export function instanceOfDBIdentificationRequest(value: object): value is DBIdentificationRequest {
    if (!('db_occupation' in value) || value['db_occupation'] === undefined) return false;
    if (!('db_name' in value) || value['db_name'] === undefined) return false;
    if (!('db_eid' in value) || value['db_eid'] === undefined) return false;
    if (!('db_specialty' in value) || value['db_specialty'] === undefined) return false;
    if (!('db_npi' in value) || value['db_npi'] === undefined) return false;
    return true;
}

export function DBIdentificationRequestFromJSON(json: any): DBIdentificationRequest {
    return DBIdentificationRequestFromJSONTyped(json, false);
}

export function DBIdentificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DBIdentificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'db_occupation': json['db_occupation'],
        'db_name': json['db_name'],
        'db_eid': json['db_eid'],
        'db_specialty': json['db_specialty'],
        'db_npi': json['db_npi'],
    };
}

export function DBIdentificationRequestToJSON(json: any): DBIdentificationRequest {
    return DBIdentificationRequestToJSONTyped(json, false);
}

export function DBIdentificationRequestToJSONTyped(value?: DBIdentificationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'db_occupation': value['db_occupation'],
        'db_name': value['db_name'],
        'db_eid': value['db_eid'],
        'db_specialty': value['db_specialty'],
        'db_npi': value['db_npi'],
    };
}

