import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Fragment, ReactNode, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

interface CalculatorInfoMessageProps {
  renderLabel?: ({ onClick }: { onClick: () => void }) => ReactNode;
  sx?: SxProps;
}

const ExampleQuestionListItemButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <ListItemButton
    disableGutters
    onClick={onClick}
    sx={{
      '&:hover': { bgcolor: 'transparent', opacity: 0.6 },
    }}
  >
    {children}
  </ListItemButton>
);

const exampleQuestions = [
  {
    // TODO(andy): Decide how to handle the buttons
    id: 'ed41681d-7391-4947-a8f5-eda913c3debb', // maybe we just link to pre-asked questions
    question: 'What are the diagnostic criteria for Kawasaki disease?',
  },
  {
    id: '790f2b00-c08d-41bc-aef1-e9aa0bbc3db6',
    question:
      'I have a 72 yo male patient with heavy alcohol use. How does that affect HAS-BLED score?',
  },
  {
    id: '60200c5d-b12d-48be-a4e9-ffcb52171436',
    question:
      'What is the CHADS2-VA2Sc score for a 54 year old female diabetic, and how should anticoagulation be handled with atrial fibrillation?',
  },
];

const fetcher = (url: string) => fetch(url).then((res) => res.json());

/**
 * Renders calculator card title, and dialog explaining the feature.
 * Use the `renderLabel` function to render a custom label.
 */
export function CalculatorInfoMessage({
  renderLabel,
  sx,
}: CalculatorInfoMessageProps) {
  const { isLoading, data, error } = useSWRImmutable(
    '/api/calculators',
    fetcher
  );
  const allCalculatorNames: string[] = data?.calculators ?? [];

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const labelContent = renderLabel?.({ onClick: handleDialogOpen }) ?? (
    // Label for the card
    <Stack
      direction='row'
      justifyContent='flex-end'
      alignItems='center'
      spacing={1}
      sx={sx}
    >
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: '0.75rem',
          bgcolor: '#fff',
          px: 1,
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={() => setDialogOpen(true)}
      >
        Clinical Calculators by OpenEvidence
      </Typography>
    </Stack>
  );

  return (
    <>
      {labelContent}
      {/* Dialog content which explains the feature */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent sx={{ p: 0 }}>
          <IconButton
            onClick={handleDialogClose}
            size='small'
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
          <Grid container sx={{ width: '100%' }}>
            <Grid
              sx={{
                bgcolor: 'grey.200',
                p: { xs: 2, md: 4 },
              }}
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                Clinical Calulators
              </Typography>
              <Typography variant='subtitle2' fontSize='1.125rem'>
                by OpenEvidence
              </Typography>
            </Grid>
            <Grid
              sx={{
                p: { xs: 2, md: 4 },
              }}
              size={{
                xs: 12,
                md: 8,
              }}
            >
              <Stack spacing={2} sx={{ mb: 4 }}>
                <Typography sx={{ fontSize: { xs: '1rem', md: '1.125rem' } }}>
                  OpenEvidence now presents interactive tools to help you
                  compute medical scores.
                </Typography>
                <Typography>
                  Simply ask about a calculation. For example:
                </Typography>
                <Card variant='outlined' sx={{ px: 1.5, bgcolor: 'grey.100' }}>
                  <List disablePadding sx={{ fontSize: '0.875rem' }}>
                    {exampleQuestions.map((example, index) => (
                      <Fragment key={example.question}>
                        <ExampleQuestionListItemButton
                          onClick={() => {
                            handleDialogClose();
                          }}
                        >
                          <Stack
                            direction='row'
                            sx={{ width: '100%', textDecoration: 'none' }}
                            component='a'
                            target='_blank'
                            href={`/ask/${example.id}`}
                          >
                            <Box
                              component='span'
                              sx={{
                                flex: 1,
                                color: 'text.primary',
                              }}
                            >
                              {example.question}
                            </Box>
                            <ChevronRightIcon
                              fontSize='small'
                              color='primary'
                            />
                          </Stack>
                        </ExampleQuestionListItemButton>
                        {index < exampleQuestions.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </List>
                </Card>
              </Stack>

              {/* Full list of calculators */}
              <Typography variant='body2'>
                We're actively working on adding support for more calculators.
                {!error &&
                  ' Please see our list of available calculators below.'}
              </Typography>
              {isLoading ? (
                <Box
                  sx={{ textAlign: 'center', mt: 2, color: 'text.secondary' }}
                >
                  <CircularProgress size={20} color='inherit' />
                </Box>
              ) : (
                <Box
                  component='ul'
                  sx={{ maxHeight: '150px', overflow: 'auto', pl: 2 }}
                >
                  {allCalculatorNames.map((calculatorName) => (
                    <Box
                      key={calculatorName}
                      component='li'
                      sx={{ fontSize: '0.875rem' }}
                    >
                      {calculatorName}
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
