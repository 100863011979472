/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NullEnum = {
} as const;
export type NullEnum = typeof NullEnum[keyof typeof NullEnum];


export function instanceOfNullEnum(value: any): boolean {
    for (const key in NullEnum) {
        if (Object.prototype.hasOwnProperty.call(NullEnum, key)) {
            if (NullEnum[key as keyof typeof NullEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function NullEnumFromJSON(json: any): NullEnum {
    return NullEnumFromJSONTyped(json, false);
}

export function NullEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NullEnum {
    return json as NullEnum;
}

export function NullEnumToJSON(value?: NullEnum | null): any {
    return value as any;
}

export function NullEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): NullEnum {
    return value as NullEnum;
}

