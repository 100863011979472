import { useMemo } from 'react';
import { MenuItem } from '@mui/material';
import {
  DataGrid,
  GridApi,
  GridColDef,
  GridCsvExportMenuItem,
  GridExportMenuItemProps,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid';
import { CITATION_LINK_SUFFIX, CtrlLink } from '../../citations';
import DomPurify from 'isomorphic-dompurify';
import he from 'he';
import {
  expandStringToList,
  splitByBracket,
} from '@xyla/openevidence-shared-functionality/src/article/components/table';

// taken almost verbatim from chat; puts things into a format so that they copy cleanly
// into things like excel and word
function getClipboardValues(apiRef: React.MutableRefObject<GridApi>) {
  const columns = apiRef.current
    .getAllColumns()
    .filter(
      (col) => col.field !== '__check__' && col.field !== '__detail_panel'
    );
  const rows = apiRef.current.getRowModels();

  const headers = columns.map((col) => col.headerName || col.field);

  const plainRows = Array.from(rows.values()).map((row) =>
    columns.map((col) => {
      const val = row[col.field];
      return val != null ? val.toString().replace(/\t/g, ' ') : '';
    })
  );

  // Create TSV for Excel/Sheets
  const tsv = [headers, ...plainRows].map((r) => r.join('\t')).join('\n');

  // Create HTML for Word
  const htmlTable = `
    <table border="1" style="border-collapse: collapse;">
      <thead>
        <tr>${headers.map((h) => `<th>${h}</th>`).join('')}</tr>
      </thead>
      <tbody>
        ${plainRows
          .map(
            (row) =>
              `<tr>${row.map((cell) => `<td>${cell}</td>`).join('')}</tr>`
          )
          .join('')}
      </tbody>
    </table>
  `.trim();

  // only very old versions of safari and firefox that are problematic
  // eslint-disable-next-line compat/compat
  return new ClipboardItem({
    'text/plain': new Blob([tsv], { type: 'text/plain' }),
    'text/html': new Blob([htmlTable], { type: 'text/html' }),
  });
}

function citationKey(citationText: string, questionIndex: number): string {
  const minCitationNumber = Math.min(...expandStringToList(citationText));
  return `${questionIndex}_${minCitationNumber}${CITATION_LINK_SUFFIX}`;
}

type TableData = Array<{ [key: string]: any }>;

export interface TableProps {
  table_data: TableData;
  citation_data: any;
  questionIndex?: number;
  askWrapperContainerId?: string;
}

export const CITATION_REGEX = /\[\d+(?:-\d+)?(?:, \d+(?:-\d+)?)*\]/g;
export const HREF_REGEX =
  /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi;

export const unicodeDecodeTableData = (tableData: TableData): TableData =>
  tableData.map((row) =>
    Object.keys(row).reduce(
      (acc, key) => ({ ...acc, [he.decode(key)]: he.decode(row[key]) }),
      {}
    )
  );

export const ArticleTable = ({
  data,
  styles,
}: {
  data: TableProps;
  styles: any;
}) => {
  const {
    table_data: raw_table_data,
    citation_data,
    questionIndex,
    askWrapperContainerId,
  } = data;

  // unicde decode all the strings in the table
  const table_data = unicodeDecodeTableData(raw_table_data);

  /** returns a list of column defs for the table. Includes a renderer for citation links
   * and a formatter to convert citations to references for the csv export */
  const columns: GridColDef[] = useMemo(() => {
    if (!table_data || !table_data[0]) return [];

    return Object.keys(table_data[0]).map((key, index) => ({
      field: key,
      headerName: key,
      sortable: false,
      flex: index < Object.keys(table_data[0]).length - 1 ? 1 : 0,
      valueFormatter: (value) => {
        if (typeof value !== 'string') return value;
        return splitByBracket(value)
          .map((term) => {
            return (
              term?.match(CITATION_REGEX)?.map(
                (match: string) =>
                  `${expandStringToList(match)
                    .map((match: number) =>
                      citation_data.referencesArr[match - 1]?.citation.replace(
                        HREF_REGEX,
                        '$3. $2'
                      )
                    )
                    .join(', ')}`
              ) ?? term
            );
          })
          .join('');
      },
      renderCell: ({ id, value }: GridRenderCellParams) => {
        if (typeof value !== 'string') return value;
        const cell_values_with_citations = splitByBracket(value)
          .map((term) => {
            return (
              term?.match(CITATION_REGEX)?.map((match: string) => (
                <span
                  className={`${styles.citations} brandable--citation`}
                  key={`citation-data-${index}-cell-${id}`}
                >
                  <CtrlLink
                    to={
                      questionIndex != null
                        ? citationKey(match, questionIndex)
                        : '1'
                    }
                    offset={0}
                    containerId={askWrapperContainerId}
                  >
                    {match}
                  </CtrlLink>
                </span>
              )) ?? (
                <span
                  key={`text-data-${index}-cell-${id}`}
                  dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(term) }}
                ></span>
              )
            );
          })
          .flat();
        return <div>{cell_values_with_citations}</div>;
      },
    }));
  }, [
    table_data,
    citation_data,
    styles.citations,
    questionIndex,
    askWrapperContainerId,
  ]);

  const rows = useMemo(() => {
    if (!table_data) return [];
    return table_data.map((row, index) => ({ ...row, id: index }));
  }, [table_data]);

  if (!table_data || !table_data[0]) {
    return <span>Error parsing table.</span>;
  }

  const Toolbar = () => {
    function CopyExportMenuItem(props: GridExportMenuItemProps<{}>) {
      const apiRef = useGridApiContext();

      const { hideMenu } = props;

      return (
        <MenuItem
          onClick={async () => {
            const clipboardValues = getClipboardValues(apiRef);
            // only very old versions of safari and firefox that are problematic
            // eslint-disable-next-line compat/compat
            await navigator.clipboard.write([clipboardValues]);
            hideMenu?.();
          }}
        >
          Copy to Clipboard
        </MenuItem>
      );
    }

    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              getRowsToExport: () => Array.from(table_data.keys()),
            }}
          />
          <CopyExportMenuItem />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        hideFooter={true}
        slots={{ toolbar: Toolbar }}
        autoHeight
        initialState={{ density: 'compact' }}
        disableRowSelectionOnClick
        disableColumnMenu
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            marginBottom: '8px !important',
          },
          '& .MuiDataGrid-columnHeader': {
            height: 'unset !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            maxHeight: '200px !important',
          },
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
              color: 'grey.700',
            },
          },
          '& .MuiDataGrid-cell': {
            overflow: 'auto',
          },
        }}
      />
    </div>
  );
};
