/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OEUserProfile,
  OEUserProfileExists,
  OEUserProfileRequest,
  PatchedOEUserProfileRequest,
} from '../models/index';
import {
    OEUserProfileFromJSON,
    OEUserProfileToJSON,
    OEUserProfileExistsFromJSON,
    OEUserProfileExistsToJSON,
    OEUserProfileRequestFromJSON,
    OEUserProfileRequestToJSON,
    PatchedOEUserProfileRequestFromJSON,
    PatchedOEUserProfileRequestToJSON,
} from '../models/index';

export interface ProfileCreateRequest {
    oEUserProfileRequest: OEUserProfileRequest;
}

export interface ProfilePartialUpdateRequest {
    patchedOEUserProfileRequest?: PatchedOEUserProfileRequest;
}

export interface ProfileUpdateRequest {
    oEUserProfileRequest: OEUserProfileRequest;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileCreateRaw(requestParameters: ProfileCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfile>> {
        if (requestParameters['oEUserProfileRequest'] == null) {
            throw new runtime.RequiredError(
                'oEUserProfileRequest',
                'Required parameter "oEUserProfileRequest" was null or undefined when calling profileCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OEUserProfileRequestToJSON(requestParameters['oEUserProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileFromJSON(jsonValue));
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileCreate(requestParameters: ProfileCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfile> {
        const response = await this.profileCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if the user profile exists
     */
    async profileExistsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfileExists>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileExistsFromJSON(jsonValue));
    }

    /**
     * Check if the user profile exists
     */
    async profileExistsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfileExists> {
        const response = await this.profileExistsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profilePartialUpdateRaw(requestParameters: ProfilePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedOEUserProfileRequestToJSON(requestParameters['patchedOEUserProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileFromJSON(jsonValue));
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profilePartialUpdate(requestParameters: ProfilePartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfile> {
        const response = await this.profilePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileFromJSON(jsonValue));
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfile> {
        const response = await this.profileRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileUpdateRaw(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OEUserProfile>> {
        if (requestParameters['oEUserProfileRequest'] == null) {
            throw new runtime.RequiredError(
                'oEUserProfileRequest',
                'Required parameter "oEUserProfileRequest" was null or undefined when calling profileUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OEUserProfileRequestToJSON(requestParameters['oEUserProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OEUserProfileFromJSON(jsonValue));
    }

    /**
     * ViewSet for viewing and editing the current user\'s profile. All operations (GET, POST, PUT, PATCH) operate on the current user\'s profile. No URL parameters are needed - all operations work on the authenticated user\'s profile.
     */
    async profileUpdate(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OEUserProfile> {
        const response = await this.profileUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
