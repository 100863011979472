/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `npi` - Npi
 * * `gmcNumber` - Gmc Number
 * * `minc` - Minc Identifier
 * * `ahpra` - Ahpra Identifier
 * * `crmNumber` - Crm Number
 * * `mohLicenseNumber` - Moh License Number
 * * `fileUpload` - File Upload
 * * `ihi` - Ihi Legacy
 * @export
 */
export const HcpIdentifierTypeEnum = {
    Npi: 'npi',
    GmcNumber: 'gmcNumber',
    Minc: 'minc',
    Ahpra: 'ahpra',
    CrmNumber: 'crmNumber',
    MohLicenseNumber: 'mohLicenseNumber',
    FileUpload: 'fileUpload',
    Ihi: 'ihi'
} as const;
export type HcpIdentifierTypeEnum = typeof HcpIdentifierTypeEnum[keyof typeof HcpIdentifierTypeEnum];


export function instanceOfHcpIdentifierTypeEnum(value: any): boolean {
    for (const key in HcpIdentifierTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(HcpIdentifierTypeEnum, key)) {
            if (HcpIdentifierTypeEnum[key as keyof typeof HcpIdentifierTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HcpIdentifierTypeEnumFromJSON(json: any): HcpIdentifierTypeEnum {
    return HcpIdentifierTypeEnumFromJSONTyped(json, false);
}

export function HcpIdentifierTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpIdentifierTypeEnum {
    return json as HcpIdentifierTypeEnum;
}

export function HcpIdentifierTypeEnumToJSON(value?: HcpIdentifierTypeEnum | null): any {
    return value as any;
}

export function HcpIdentifierTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): HcpIdentifierTypeEnum {
    return value as HcpIdentifierTypeEnum;
}

