import { CountryTier, getCountriesInTier } from '@xyla/util';
import { HcpIdentifierTypeEnum } from './OEUserProfileAPI/_generated/models/HcpIdentifierTypeEnum';

export interface HCPIdentifier {
  // the name of the identifier
  type: HcpIdentifierTypeEnum;

  // the locality it applies to
  locality: string;

  // the user-facing label, e.g. `NPI`
  label: string;

  // a regexp to validate the identifier
  pattern?: RegExp;

  // an error message to show if the pattern does not match
  message?: string;

  // placeholder text for the input field
  placeholder?: string;

  // Which countries to use this identifier for.
  // This overrides excludedCountries.
  includedCountries?: string[];

  // Which countries to NOT use this identifier for.
  // This is overridden by includedCountries.
  excludedCountries?: string[];

  // validation function that returns the cleaned identifier if valid or null
  // if the identifier is not valid. The function is bound to the Identifier
  // object to help with typing.
  validate?(this: HCPIdentifier, value?: string): string | null;
}

export const HCP_IDENTIFIERS: HCPIdentifier[] = [
  {
    type: HcpIdentifierTypeEnum.Npi,
    locality: 'United States',
    label: 'NPI',
    message: 'Invalid NPI Number',
    includedCountries: getCountriesInTier(CountryTier.TIER_1),
    validate(value?: string) {
      /**
       * Validates that the inputted text is a valid NPI.
       *
       * NPIs are 10 digit numbers that when prefixed with 80840 satisfy the Luhn algorithm.
       * Source: https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
       */
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/[- ]/g, '');

      if (cleaned.length !== 10) {
        return null;
      }

      const digitArray = cleaned.split('').map((digit) => Number(digit));
      if (![1, 2].includes(digitArray[0])) {
        return null;
      }
      if (digitArray.some((digit) => isNaN(digit))) {
        return null;
      }

      const checkDigit = digitArray[9];
      // The checkSum value of the 80840 prepended prefix is 24 according to
      // the Luhn algorithm. The NPI number has 1 check digit and 9 non-check
      // digits, so starting with the right most non-check digit and doubling
      // every other digit means the right-most digit in 80840 is kept the
      // same:
      //      8 0 8 4 0 => 8 0 8 (4*2=8) 0
      // Summing together the digits:
      //      8 0 8 8 0 => 8 + 0 + 8 + 8 + 9 = 24
      let checkSum = 24;

      // Loop over the first 9 digits of the NPI in reverse order
      for (let i = 8; i >= 0; i--) {
        let digitSum = digitArray[i];
        // For a NPI digit d, if 2 * d = n is a 2 digit number, the sum of the
        // digits of n is equal to (n - 9) because for the two-digit number
        // `1x`, `1x` - 9 = 10 + x - 9 = 1 + x.
        // For example, 2(7) = 14. Then 14 - 9 = 10 + 4 - 9 = 1 + 4, which
        // equals is the sum of the individual digits of 14 We do this for
        // every other digit, starting with the rightmost non-check digit.
        if (i % 2 === 0) {
          digitSum *= 2;
          if (digitSum > 9) {
            digitSum -= 9;
          }
        }
        checkSum += digitSum;
      }

      // The checkSum and the checkDigit need to be additive inverses modulo 10
      if (!((checkSum + checkDigit) % 10 === 0)) {
        return null;
      }

      // It is valid
      return cleaned;
    },
  },
  {
    type: HcpIdentifierTypeEnum.GmcNumber,
    locality: 'United Kingdom',
    label: 'GMC Number',
    includedCountries: ['GB', 'IE'],
    validate(value?: string) {
      // See https://www.gmc-uk.org/registration-and-licensing/employers-medical-schools-and-colleges/employing-a-doctor/gmc-reference-numbers
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/-/g, '');
      if (cleaned.length === 7 && !isNaN(Number(cleaned))) {
        return cleaned;
      }

      return null;
    },
  },
  {
    type: HcpIdentifierTypeEnum.Minc,
    locality: 'Canada',
    label: 'MINC',
    includedCountries: ['CA'],
    validate(value?: string) {
      // This could be improved by validating the check digit
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/-/g, '');

      // MINC is four characters followed by 8 digits after removing all -
      // Can also just be the 8 numbers
      if (cleaned.length === 12 && cleaned.match(/^[A-Za-z]{4}\d{8}$/)) {
        return cleaned;
      } else if (cleaned.length === 8 && cleaned.match(/^\d{8}$/)) {
        return cleaned;
      }

      return null;
    },
  },
  {
    type: HcpIdentifierTypeEnum.Ahpra,
    locality: 'Australia',
    label: 'AHPRA',
    includedCountries: ['AU'],
    validate(value?: string) {
      // This could be improved by validating the check digit
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/-/g, '').toUpperCase();

      // Regex pattern: matches specific prefixes followed by exactly 10 digits
      // source: https://www.wikidata.org/wiki/Property:P10094
      const regex =
        /^(ATS|CHI|CMR|DEN|MED|MRP|NMW|OCC|OPT|OST|PAR|PHA|PHY|POD|PSY)\d{10}$/;
      // Check if the value matches the regex pattern
      if (regex.test(cleaned)) {
        return cleaned;
      }

      return null;
    },
  },
  {
    type: HcpIdentifierTypeEnum.CrmNumber,
    locality: 'Brazil',
    label: 'CRM Number',
    pattern: /[A-Z][A-Z]([- ]*)\d+/,
    message: 'Enter a valid CRM Number in the format UF-00000',
    placeholder: 'UF-00000',
    includedCountries: ['BR'],
    validate(value?: string) {
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/[- ]/g, '').toUpperCase();

      // test that the first two chars match a federal unit
      if (!BRAZIL_FEDERAL_UNIT_CODES.includes(cleaned.substring(0, 2))) {
        return null;
      }

      if (this.pattern?.test(cleaned)) {
        // add back the hyphen
        return cleaned.substring(0, 2) + '-' + cleaned.substring(2);
      }

      return null;
    },
  },
  {
    type: HcpIdentifierTypeEnum.MohLicenseNumber,
    locality: 'Israel',
    label: 'MOH License Number',
    pattern: /^(\d\d?-)?\d+$/,
    message: 'Enter a valid MOH License Number in the format 00000 or 0-00000',
    includedCountries: ['IL'],
    validate(value?: string) {
      // Per:
      // - https://www.gov.il/en/service/licensed-medical-practitioners
      // - https://practitioners.health.gov.il/Practitioners/1
      // The first part (1-2 digits) is the type of practitioner
      // The second part around 5-6 digits is the license number
      // Not able to find a more authoritative source on how these are constructed or how to validate them.
      if (!value) {
        return null;
      }

      let cleaned = value.replace(/ /g, '');

      // reject the placeholder
      if (cleaned.startsWith('0') || /^[0-]+$/.test(cleaned)) {
        return null;
      }

      // standardize 12345 to 1-12345 (physician category)
      if (cleaned.match(/^\d+$/)) {
        cleaned = '1-' + cleaned;
      }

      if (this.pattern?.test(cleaned)) {
        return cleaned;
      }

      return null;
    },
  },
  {
    type: HcpIdentifierTypeEnum.FileUpload,
    locality: 'N/A',
    label: 'Other',
    excludedCountries: getCountriesInTier(CountryTier.TIER_1),
  },
];

// From https://en.wikipedia.org/wiki/Federative_units_of_Brazil#List
export const BRAZIL_FEDERAL_UNIT_CODES = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
];

export interface MedicalStudentIdentifier {
  // the locality it applies to
  locality: string;

  // validation function that returns the cleaned identifier if valid or null
  // if the identifier is not valid. The function is bound to the Identifier
  // object to help with typing.
  validate?(this: MedicalStudentIdentifier, email?: string): string | null;
}

export const MEDICAL_STUDENT_IDENTIFIERS: MedicalStudentIdentifier[] = [
  {
    locality: 'United States',
    validate(email?: string) {
      if (!email) {
        return null;
      }

      const trimmedEmail = email.trim().toLowerCase();

      return trimmedEmail.endsWith('.edu') ? trimmedEmail : null;
    },
  },
];
