'use client';

import { FieldValues, SubmitHandler } from 'react-hook-form';
import { OEAccessRoleName } from '../types';
import { useCallback, useState } from 'react';
import { getAuth0UpdateBodies } from './getAuth0UpdateBodies';
import { UserDataPayload } from '../users/types';
import { SIGNUP_DISALLOWED_ERROR_MSG } from '../constants';

export interface SendMixpanelSignupEventProps<TUserDataPayload> {
  body: TUserDataPayload;
  accessLevelToGive: OEAccessRoleName | null;
  hasProcessedUser: boolean;
}

export type SendMixpanelSignupEventPropsWithReferrer<TUserDataPayload> =
  SendMixpanelSignupEventProps<TUserDataPayload> & {
    referringPage: string | undefined;
  };

type ServerResponseData = {
  accessLevelToGive: OEAccessRoleName | null;
};

interface UseOnboardingBaseProps<
  TFieldValues extends FieldValues,
  TUserDataPayload extends UserDataPayload
> {
  hasProcessedUser: boolean;
  constructUserDataPayload: (data: TFieldValues) => TUserDataPayload;
  uploadFiles?: (data: TFieldValues) => Promise<void>;
  sendMixpanelSignupEvent: (
    props: SendMixpanelSignupEventProps<TUserDataPayload>
  ) => Promise<void>;
  redirectAndLoginAfterCompletion: () => void;
  captureException: (error: any) => void;
  setAuth0DataServerUrl: string;
  setAuth0DataExtraHeaders?: () => Promise<Record<string, string> | undefined>;
}

/* Handles the generic logic of onboarding:
 * - Update the auth0 data
 * - Sends the data to Mixpanel
 * - Redirects to login after completion
 * This should not be specific to web vs. app
 */
export function useOnboardingBase<
  TFieldValues extends FieldValues,
  TUserDataPayload extends UserDataPayload
>({
  hasProcessedUser,
  constructUserDataPayload,
  uploadFiles,
  sendMixpanelSignupEvent,
  redirectAndLoginAfterCompletion,
  captureException,
  setAuth0DataServerUrl,
  setAuth0DataExtraHeaders,
}: UseOnboardingBaseProps<TFieldValues, TUserDataPayload>) {
  const [loading, setLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const sendData: SubmitHandler<TFieldValues> = useCallback(
    async (data) => {
      // Send the data to the server
      // We send the data in a form so we can handle files
      setLoading(true);
      setSubmitError(false);
      setVerificationError(false);
      const dataPayload = constructUserDataPayload(data);

      // Upload any files to GCS
      try {
        await uploadFiles?.(data);
      } catch (error) {
        captureException(error);
        setSubmitError(true);
        setLoading(false);
        return;
      }

      // Convert the data payload to the body data for the requests to auth0
      const auth0UpdateBodies = getAuth0UpdateBodies({
        auth0Metadata: dataPayload,
      });

      let responseData: ServerResponseData;
      try {
        const response = await fetch(setAuth0DataServerUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(await setAuth0DataExtraHeaders?.()),
          },
          body: JSON.stringify(auth0UpdateBodies),
        });
        if (response.ok) {
          responseData = await response.json();
        } else if (response.status === 403) {
          // Not allowed to sign up due to country, see IsCountryAllowedSignup in piapiac
          setSubmitError(true);
          setErrorMessage(SIGNUP_DISALLOWED_ERROR_MSG);
          setLoading(false);
          return;
        } else if (400 <= response.status && response.status < 500) {
          // Some sort of verification error
          setSubmitError(true);
          setVerificationError(true);
          setLoading(false);
          return;
        } else {
          throw new Error('Error when setting user info');
        }
      } catch (error) {
        captureException(error);
        setSubmitError(true);
        setVerificationError(false);
        setLoading(false);
        return;
      }

      setSubmitSuccess(true);
      setSubmitError(false);
      setVerificationError(false);

      // Send the data to Mixpanel
      // Only send the signup event if we have not processed the user before
      if (!hasProcessedUser) {
        const accessLevelToGive = responseData.accessLevelToGive;

        await sendMixpanelSignupEvent({
          body: dataPayload,
          accessLevelToGive,
          hasProcessedUser,
        });
      }

      // Redirect to login
      redirectAndLoginAfterCompletion();
    },
    [
      constructUserDataPayload,
      uploadFiles,
      captureException,
      sendMixpanelSignupEvent,
      hasProcessedUser,
      redirectAndLoginAfterCompletion,
      setSubmitError,
      setSubmitSuccess,
      setLoading,
      setAuth0DataServerUrl,
      setAuth0DataExtraHeaders,
    ]
  );

  const clearSubmitStatus = useCallback(() => {
    setSubmitError(false);
    setVerificationError(false);
    setSubmitSuccess(false);
  }, [setSubmitError, setSubmitSuccess]);

  return {
    loading,
    submitError,
    verificationError,
    errorMessage,
    clearSubmitStatus,
    submitSuccess,
    sendData,
    miscHelpEmail: 'help@openevidence.com',
  };
}
