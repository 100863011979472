import { cloneElement } from 'react';
import parse from 'html-react-parser';

import { ct_to_citation_string, CitationSpan } from '../citations';
import { ArticleFigure, Citation } from '../types';

import { RenderFunctionFilter, RenderSpan } from './spans';
import { FigureRenderer } from '@xyla/openevidence-shared-functionality/src/article/deriveGeneratedArticleBase';

const cite_in_element = (
  el: JSX.Element,
  replace_class_name: string,
  referencesArr: Citation[],
  i: number,
  j: number,
  k: number,
  cite_num: number,
  styles: Record<string, string>,
  disableCitationLinks?: boolean
): [JSX.Element, number] => {
  /* Recursive function that takes an element and finds any data that needs to be cited,
   * indicated by an element with a specific class, and replaces the element with a citation
   * that points to provided data
   */

  // If the element is not actually a react element, just return it as is
  if (el.props === undefined) {
    return [el, 0];
  }

  // If we've found something that needs replacing, replace it!
  if (el.props.className === replace_class_name) {
    // Read this string from JSON and convert the data into citation strings
    let all_citation_data = JSON.parse(el.props.children);
    let citations = [];
    for (let citation_data of all_citation_data) {
      citations.push(ct_to_citation_string(citation_data));
    }

    // Convert the citation strings into an element with the citation and link
    const citation_span = (
      <CitationSpan
        citations={citations}
        references={referencesArr}
        prefix={`${i}_${j}_${k * 1000 + cite_num}`}
        styles={styles}
        disableLinks={disableCitationLinks}
      />
    );
    return [<>{citation_span}</>, cite_num + 1];

    // If the element doesn't need replacing, see if we need to check the children
  } else {
    if (Array.isArray(el.props.children)) {
      let new_children = [];
      for (let child of el.props.children) {
        let [new_child, new_cite_num] = cite_in_element(
          child,
          replace_class_name,
          referencesArr,
          i,
          j,
          k,
          cite_num,
          styles,
          disableCitationLinks
        );
        new_children.push(new_child);
        cite_num = new_cite_num;
      }
      el = cloneElement(el, {}, new_children);
    }
  }
  return [el, cite_num];
};

const renderFigureSpans = (
  articleFigure: ArticleFigure,
  referencesArr: Citation[],
  i: number,
  j: number,
  k: number,
  styles: Record<string, string>,
  additionalRenderFunctions: Record<string, RenderFunctionFilter>,
  additionalComponentData: Record<string, unknown>,
  disableCitationLinks?: boolean
): JSX.Element | JSX.Element[] | null => {
  const { figure_html: figureHtml } = articleFigure;

  if (figureHtml === '') {
    // If there are no figure_spans render null
    return null;
  }

  if (articleFigure.figurespan_set.length > 0) {
    const figureSpans = articleFigure.figurespan_set
      .map((articleSpan, spanIndex) =>
        RenderSpan({
          articleSpan,
          references: referencesArr,
          prefix: `${i}_${j}_${k * 91233 + spanIndex}`,
          styles,
          disableCitationLinks,
          additionalRenderFunctions,
          additionalComponentData,
        })
      )
      .filter((element): element is JSX.Element => element !== null);

    return figureSpans.length === 0 ? null : (
      <div key={`${i}-${k}-${k}`} className={styles.paragraph_p}>
        {figureSpans}
      </div>
    );
  }

  // If there are no figure_spans, then make the figure as usual
  if (articleFigure.figure_html === '') {
    return null;
  }
  // Parse the html string into React elements
  const parsedFigureElement = parse(figureHtml, { trim: true });
  const preCitationFigureElement = Array.isArray(parsedFigureElement)
    ? parsedFigureElement
    : [];

  // Replace citations in all components
  const figureElements = [];
  let citationNum = 0;
  for (const currentFigure of preCitationFigureElement) {
    const [nextFigure, nextCitationNum] = cite_in_element(
      currentFigure,
      '@CITE@',
      referencesArr,
      i,
      j,
      k,
      citationNum,
      styles,
      disableCitationLinks
    );
    citationNum = nextCitationNum;
    figureElements.push(nextFigure);
  }
  return figureElements;
};

export const renderFigure: FigureRenderer = ({
  articleFigure,
  references: referencesArr,
  index: i,
  j,
  k,
  styles,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
}) => {
  const figureSpans = renderFigureSpans(
    articleFigure,
    referencesArr,
    i,
    j,
    k,
    styles,
    additionalRenderFunctions,
    additionalComponentData,
    disableCitationLinks
  );

  const captions = articleFigure.captionspan_set
    .map((articleSpan, spanIndex) =>
      RenderSpan({
        articleSpan,
        references: referencesArr,
        prefix: `${i}-${j}-${(k + 1) * 10000 + spanIndex}`,
        styles,
        disableCitationLinks,
        additionalRenderFunctions,
        additionalComponentData,
      })
    )
    .filter((element): element is JSX.Element => element !== null);

  return figureSpans === null ? null : (
    <div
      className={styles.figure_div}
      style={{ flex: articleFigure.flex }}
      key={`${i}-${j}-${k}_figure`}
    >
      <div className={styles.figure_html_div}>{figureSpans}</div>
      <div className={styles.figure_caption_p}>{captions}</div>
    </div>
  );
};
