/* tslint:disable */
/* eslint-disable */
/**
 * OE User Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OEUserProfileExists
 */
export interface OEUserProfileExists {
    /**
     * 
     * @type {boolean}
     * @memberof OEUserProfileExists
     */
    _exists: boolean;
}

/**
 * Check if a given object implements the OEUserProfileExists interface.
 */
export function instanceOfOEUserProfileExists(value: object): value is OEUserProfileExists {
    if (!('_exists' in value) || value['_exists'] === undefined) return false;
    return true;
}

export function OEUserProfileExistsFromJSON(json: any): OEUserProfileExists {
    return OEUserProfileExistsFromJSONTyped(json, false);
}

export function OEUserProfileExistsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OEUserProfileExists {
    if (json == null) {
        return json;
    }
    return {
        
        '_exists': json['exists'],
    };
}

export function OEUserProfileExistsToJSON(json: any): OEUserProfileExists {
    return OEUserProfileExistsToJSONTyped(json, false);
}

export function OEUserProfileExistsToJSONTyped(value?: OEUserProfileExists | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exists': value['_exists'],
    };
}

