import { ArticleData, Citation, RichCitation } from './types';

export const DEFAULT_ARTICLE_DATA: ArticleData = {
  title: '',
  byline: '',
  what_is_this_page_html: '',
  page_type: '',
  cms_type: '',
  medically_reviewed_by: '',
  medically_audited_by: '',
  audited_by: '',
  articlesection_set: [],
  intervention_as: [],
  condition_as: [],
  lead_image_url: '',
  image_url: '',
  related_article_slugs: [],
};

export function isRichCitation(reference: Citation): reference is RichCitation {
  return (
    typeof reference !== 'string' && Boolean(reference.metadata.citation_detail)
  );
}

/** Get all citations in the article, in order. Does not dedupe. */
export function getCitations(article: ArticleData): Citation[] {
  const citations: Citation[] = [];
  article.articlesection_set.forEach((section) => {
    section.articleparagraph_set.forEach((paragraph) => {
      paragraph.articlespan_set.forEach((span) => {
        citations.push(...span.citations);
      });
    });
  });
  return citations;
}

/** Get all unique citations in the article, in the order that they appear. */
export function getUniqueCitations(article: ArticleData): Citation[] {
  const citations = getCitations(article);
  const getCitationKey = (citation: Citation) =>
    isRichCitation(citation) ? citation.citation : citation;
  return uniqueElementsInOrder(citations, getCitationKey);
}

/** Return the subset of unique list elements in the order that they appear. */
export function uniqueElementsInOrder<T>(
  elements: T[],
  getKey: (element: T) => string
): T[] {
  const elementSet = new Set<string>();
  return elements.filter((element) => {
    const key = getKey(element);
    if (elementSet.has(key)) {
      return false;
    }
    elementSet.add(key);
    return true;
  });
}

/** Equivalent to python string rstrip. */
export function rtrim(s: string, chars: string): string {
  const charSet = new Set(chars);

  let i = s.length;
  while (i > 0 && charSet.has(s[i - 1])) {
    i--;
  }
  return s.slice(0, i);
}

/** Force the string to end with a period. */
export function endWithPeriod(referenceString: string): string {
  return rtrim(referenceString, '. ') + '.';
}
