import getArticleRichText from '@xyla/openevidence-shared-functionality/src/article/copypaste';
import { ArticleData } from './types';
import { getUniqueCitations } from './utils';

const PAGE_MARGIN = '1in';

export const openPrintWindow = (article: ArticleData) => {
  const printWindow = window.open('', '_blank', 'width=800,height=600');

  const references = getUniqueCitations(article);
  const articleRichText = getArticleRichText(article, references);
  if (printWindow) {
    printWindow.document.open();
    printWindow.document.write(`
        <html>
        <head>
            <title>${article.title}</title>
            <style>
                /* Base styles */
                body {
                    font-family: Times, serif;
                    line-height: 1.5;
                    margin: ${PAGE_MARGIN};
                    padding: 0;
                }

                .print-container {
                    margin: ${PAGE_MARGIN};
                }

                /* Table improvements */
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin: 1em 0;
                    font-size: 14px;
                }

                table, th, td {
                    border: 1px solid black;
                }

                th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                    text-align: left;
                    padding: 8px;
                }

                td {
                    padding: 8px;
                }

                tr:nth-child(even) {
                    background-color: #f9f9f9;
                }

                /* Print-specific styles */
                @media print {
                    @page {
                        size: A4;
                        margin-top: ${PAGE_MARGIN};
                        margin-bottom: ${PAGE_MARGIN};
                    }

                    @page:first {
                        margin-top: 0;
                    }

                    html, body {
                        -webkit-print-color-adjust: exact;
                        print-color-adjust: exact;
                        max-width: 100%;
                        width: 100%;
                        margin: 0 !important;
                        padding: 0 !important;
                    }

                    /* Typography improvements */
                    h1, h2, h3, h4, h5, h6 {
                        break-after: avoid;
                        break-inside: avoid;
                    }

                    /* Ensure tables don't break awkwardly in print */
                    table {
                        page-break-inside: avoid;
                    }

                    th, td {
                        border: 1px solid black !important;
                    }
                }
            </style>
        </head>
        <body>
            <div class="print-container">
              ${articleRichText}
            </div>
            <script>
              window.addEventListener('load', function() {
                  window.print();
                  window.close();
              });
            </script>
        </body>
        </html>
    `);
    printWindow.document.close();
  }
};
